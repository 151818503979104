.par-phase-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .phase-button-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 20px 0 0 0;
    height: 50px;

    .phase-code-button {
      width: 85%;
      background: #383838;
      color: $background-white;
      font-size: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-right: none;
      cursor: pointer;
    }

    .remove-modal {
      background: #383838;
      color: $background-white;
      font-size: 20px;
      cursor: pointer;
      border-left: none;
      padding-right: 10px;
    }
  }

  .button-wrapper {
    display: flex;

    button {
      width: 100px;
      height: 30px;
      margin: 20px 10px;
      border-radius: 15px;
      border: none;
      color: $background-white;
      cursor: pointer;
    }

    .minimize-button {
      background-color: black;
    }

    .clear-button {
      background-color: #767676;
    }
  }
}

.par-checkbox-container {
  display: flex;
  justify-content: center;
  max-width: 85vw;
  accent-color: black;
  margin: 1% auto;
  padding-top: 3%;

  input[type="checkbox"] {
    transform: scale(1.15);
  }

  input[type="checkbox"] {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    cursor: pointer;
    font-size: 17px;
    visibility: hidden;
  }

  input[type="checkbox"]:after,
  input[type="checkbox"]::after {
    content: " ";
    width: 18px;
    height: 18px;
    background-color: black;
    display: inline-block;
    color: #00bff0;
    visibility: visible;
    border: 4px black solid;
  }

  input[type="checkbox"]:checked:after,
  input[type="checkbox"]:checked::after {
    font-weight: bold;
    font-size: 20px;
    background-color: black;
    color: $background-white;
  }

  input[type="checkbox"]:disabled:after,
  input[type="checkbox"]:disabled::after {
    padding: -5px;
    font-weight: bold;
    font-size: 20px;
    background-color: black;
    color: $background-white;
  }

  .checkbox-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    gap: 30px;

    .checkbox-wrapper {
      display: grid;
      grid-template-columns: 9fr 1fr;
      justify-items: center;
      align-items: center;
      max-width: max(8vw, 180px);
      min-width: min(8vw, 180px);
      padding: 5px 5px 5px 5px;
      background-color: #767676;

      .checkbox-label {
        color: $background-white;
        font-size: 18px;
        font-weight: 400;
        padding: 0;
        margin: 0;
      }
    }
  }
}

@media (max-width: "768px") {
  .hrvc-form-container {
    .close-button {
      margin-left: 82%;
    }

    .information-wrapper {
      .left-side-container {
        .left-side-header {
          margin: 15px 6px;
        }

        .select-employee-wrapper {
          flex-direction: column;
          align-items: center;

          .left-side-btn {
            top: unset;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

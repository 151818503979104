.report-table-wrapper {
  display: grid;
  grid-template-columns: 100px auto;
  margin: 5px 0;

  .table-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: 0.5em;
    overflow-x: auto;
    max-width: 90vw;

    table {
      width: 90%;
      background-color: white;
      font-size: 18px;
      text-align: center;

      .issue-type,
      .date-raised,
      .vc-employee-name,
      .vc-employee-number {
        white-space: nowrap;
      }
    }

    table,
    th,
    td {
      border: 1px solid black;
      border-collapse: collapse;
    }

    td {
      padding: 2px 10px;
    }
  }
}

.overview-page-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 100px);
  overflow: hidden;
  margin-top: 100px;

  .header-wrapper {
    @include flex-center;
    position: relative;

    .header-h1 {
      text-align: center;
      font-size: 40px;
      letter-spacing: 4px;
    }

    .external-links-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;
      position: absolute;
      right: 0px;
      width: 35%;
      max-width: 440px;
    }
  }

  .individual-notifications-containers {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, 14vh);
    row-gap: 10px;
    column-gap: 8%;
    width: 90%;
    margin: auto;
    margin-top: auto;

    h1 {
      letter-spacing: 2px;
      font-size: 24px;
      font-weight: 500;
    }

    :nth-child(1) {
      grid-row: span 2;
      text-align: center;
    }

    :nth-child(2) {
      grid-row: span 3;
    }

    :nth-child(3) {
      grid-row: span 2;
      margin-top: auto;
    }

    .scroll {
      overflow-y: auto;
      height: 28vh;

      .overview-report-link {
        color: black;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          opacity: 0.5;
        }
      }
    }

    .approvals {
      height: 45vh;
    }

    .safety {
      padding-top: 0.6%;
    }

    .par-box {
      display: flex;
      align-items: center;
      width: 99%;
      height: 6vh;
      margin: 2px 0;
      border: 1px solid;

      * {
        padding: 10px;
      }

      h3 {
        width: 100px;
        text-align: left;
        font-size: 16px;
      }

      h4 {
        margin: auto 0 auto 0;
      }

      .par-status {
        margin-left: auto;
      }

      .fa-trash {
        color: black;

        &:hover {
          opacity: 0.5;
          cursor: pointer;
        }
      }
    }

    .green-gradient {
      @include green-gradient;
    }

    .blue-gradient {
      @include blue-gradient;
    }

    .orange-gradient {
      @include orange-gradient;
    }

    .red-gradient {
      @include red-gradient;
    }

    .purple-gradient {
      @include purple-gradient;

      .fa-phone {
        color: #47047b;
        width: 2.5%;
      }

      h3 {
        width: 30%;
        text-align: center;
      }

      .safety-name {
        text-align: left;
      }

      .safety-phone {
        margin-right: 5%;
        margin-top: 2.5%;
      }
    }

    h2 {
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 3px;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import "theme.scss";
@import "./mixins.scss";
@import "auth-page.scss";
@import "navbar.scss";
@import "external-link.scss";
@import "toggle-slider.scss";
@import "login-page.scss";
@import "universal-search.scss";
@import "toast-notifications.scss";
@import "overview-page.scss";
@import "par-page.scss";
@import "./approvals-page.scss";
@import "./verification-page/verification-page.scss";
@import "./verification-page/report-header.scss";
@import "./verification-page/report-details.scss";
@import "./verification-page/report-notes.scss";
@import "./verification-page/report-table.scss";
@import "./verification-page/report-phase-tables.scss";
@import "./verification-page/verification-totals.scss";
@import "./verification-page/report-signatures.scss";
@import "./verification-page/report-buttons.scss";
@import "./approver-changes.scss";
@import "./no-match-page.scss";
@import "custom-components/general-checkbox.scss";
@import "custom-components/horizontal-rule.scss";
@import "./action-buttons.scss";
@import "./par-notification-item.scss";

@import "./par-page/job-information-section/hours-worked.scss";
@import "./par-page/par-job-information-section/weather-information.scss";
@import "./par-page/site-activity-checkboxes/verbal-counsel-section.scss";
@import "./par-page/site-activity-checkboxes/issues-section.scss";
@import "./par-page/site-activity-checkboxes/injuries-section.scss";
@import "./par-page/site-activity-checkboxes/subcontractors-section.scss";
@import "./par-page/expand-collapse-section.scss";
@import "./par-page/site-activity-checkboxes/time-materials-section.scss";
@import "./par-page/site-activity-checkboxes/site-activity-checkboxes.scss";
@import "./par-page/site-activity-checkboxes/materials-section.scss";
@import "./par-page/journal-entries-section.scss";
@import "./par-page/phase-code-section/phase-code-item.scss";
@import "./par-page/phase-code-section/phase-code-sub-section.scss";
@import "./par-page/phase-code-section/phase-codes-section.scss";
@import "./par-page/phase-code-section/phase-note.scss";

@import "./modals/add-issue-form.scss";
@import "./modals/autofill-modal.scss";
@import "./modals/confirmation-modal.scss";
@import "./modals/general-modal-layout.scss";
@import "./modals/print-email-form.scss";
@import "./modals/reject-comments.scss";
@import "./modals/verbal-counsel-form.scss";

@import "./loading-spinner.scss";

// Leave media queries at the bottom
@import "./media-queries/index.scss";

body {
  margin: 0px;
  font-family: "Roboto", sans-serif;
}

.body-wrapper {
  margin-top: 70px;
  margin-left: 0px;
}

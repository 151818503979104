@include checkbox-table;

tbody {
  width: 100%;
  background-color: white;

  .material-table-row {
    width: 100%;
    border: 1px solid black;

    td {
      div {
        display: flex;
        align-items: center;

        .mat-phase-add-btn {
          height: 50%;
          background-color: #767676;
          color: $background-white;
          padding: 4px 0;
          border: none;
          font-size: 16px;
          border-radius: 0;
          margin-left: 3px;
          cursor: pointer;
        }
      }

      .phase-wrapper {
        position: relative;
        width: 80%;
        justify-content: space-between;

        &:hover::after {
          content: attr(data-title);
          padding: 5px;
          width: fit-content;
          border: 1px solid #000;
          position: absolute;
          top: 40px;
          left: -5px;
          background: #383838;
          color: $background-white;
          z-index: 50;
        }

        .chosen-code {
          width: 350px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .mat-input {
      width: 5vw;
      overflow-x: auto;
    }

    svg {
      font-size: 16px;
      color: white;
    }

    &:hover {
      background-color: #767676;
      color: white;

      .mat-phase-add-btn {
        color: white;
        background-color: transparent;
        border: 1px solid white;
        background-color: white;
        color: #767676;
        display: inline;
        cursor: pointer;
      }

      svg {
        cursor: pointer;
      }
    }

    td {
      padding: 10px;
    }
  }
}

.report-buttons-wrapper {
  display: grid;
  grid-template-columns: 100px auto;

  .buttons {
    grid-column: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }

  button,
  a {
    width: 120px;
    height: 30px;
    color: $background-white;
    background-color: black;
    margin: 10px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
  }

  button:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

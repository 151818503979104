.hours-worked-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .time-input-wrapper {
    input {
      width: 298px;
    }
  }
}

.navbar-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding-left: 10px;
  line-height: 50px;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(62, 81, 104, 0.49);
  -moz-box-shadow: 0px 1px 4px 0px rgba(62, 81, 104, 0.49);
  box-shadow: 0px 1px 4px 0px rgba(62, 81, 104, 0.49);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  background-image: url("../img/nav-bar-background.svg");
  background-size: cover;

  .bhi-logo-container {
    @include flex-column-center;
    height: 100px;
    width: 300px;
    z-index: 1;

    .user-name {
      color: white;
      padding-top: 15%;
      margin-right: 10%;
      font-weight: 500;
      font-size: large;
      letter-spacing: 2px;
    }

    .logo-wrapper {
      img {
        height: 100px;
        position: relative;
        margin-left: 5%;
      }
    }

    a.logo-wrapper {
      display: flex;
    }
  }

  .hamburger-menu-container {
    .hamburger-menu {
      cursor: pointer;
      display: none;
    }

    .navigation-menu {
      display: flex;
    }

    a {
      text-decoration: none;
      color: #e5e5e5;
      letter-spacing: 2px;
      font-size: 22px;
    }
  }

  .nav-item {
    margin-right: 50px;

    &:hover {
      text-decoration: underline;
    }
  }
}

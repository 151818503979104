@media (max-width: 1180px) {
  .verification-page-container {
    .section-three {
      .tables-wrapper {
        .empty-data-container {
          margin-left: 9%;
        }
      }
    }
  }
}

@media (max-width: 1121px) {
  .overview-page-container {
    overflow: visible;

    .header-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .approve-submissions-header {
        font-size: 4vw;
        margin: 0 0 0 5%;
      }

      .external-links-wrapper {
        @include flex-column-center;
        gap: 50px;
        margin: 25px 0;
        position: unset;
        width: 100%;
        max-width: unset;
      }
    }

    .individual-notifications-containers {
      grid-template-columns: none;
      grid-template-rows: none;
      padding-bottom: 10%;

      .scroll {
        overflow-y: scroll;
        width: 65vw;
        height: 32.7vh;
        min-width: 560px;
      }
    }
  }
}
@media (max-width: 820px) {
  .verification-page-container {
    .section-three {
      .tables-wrapper {
        .empty-data-container {
          width: 80%;
          margin-left: 22%;
          gap: 10px;
          .no-phase-data {
            width: 125px;
          }

          .short {
            width: 95px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .overview-page-container {
    .header-wrapper {
      display: flex;
      flex-direction: column;

      .header-h1 {
        margin-left: 0px;
      }
    }
  }
}

@media (max-width: 650px) {
  .App {
    .container {
      .body-wrapper {
        .overview-page-container {
          .header-wrapper {
            display: flex;
            flex-direction: column;

            .header-h1 {
              margin-top: 15%;
              margin-left: 0px;
            }
          }

          .individual-notifications-containers {
            .notifications-container {
              .approvals {
                width: 92vw;
                margin-bottom: 10%;

                .par-box {
                  height: fit-content;
                }
              }

              .par {
                min-width: 92vw;
                width: 92vw;
                margin-bottom: 10%;
                margin-left: auto;
                margin-right: auto;
                display: flex;
                flex-wrap: wrap;

                .par-box {
                  display: flex;
                  flex-wrap: wrap;
                  height: fit-content;
                  justify-content: space-between;

                  .par-status {
                    margin-left: 0px;
                  }
                }
              }

              .approvals {
                min-width: 92vw;
                margin-left: auto;
                margin-right: auto;

                .par-box {
                  display: flex;
                  flex-wrap: wrap;
                  height: fit-content;
                  justify-content: space-between;

                  .par-status {
                    margin-left: 0px;
                  }
                }
              }

              .safety-scroll {
                width: 92vw;
                margin-bottom: 20%;

                .par-box {
                  h4 {
                    width: 45%;
                  }
                }
              }
            }
          }
        }
      }
    }

    .login-page-container {
      .login-wrapper {
        form {
          width: 65%;
        }
      }
    }
  }
}

.nested {
  width: 100%;

  .show-hide-wrapper {
    button {
      font-size: 18px;
      background-color: lighten(#383838, 30%);
      border: 1px solid lighten(#383838, 30%);
      height: 35px;
    }

    .single-problem-container {
      textarea {
        background-color: white;
        text-align: left;
        cursor: text;
      }
    }
  }
}

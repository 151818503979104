$darkest: black;
$second-darkest: #3e5168;
$second-lightest: #6c8cb5;
$lightest: #8ab3e8;
$light-grey: #cccccc;
$dark-grey: #aaaaaa;
$background-white: #ffffff;
$rose: #a2666f;
$coral: #f49390;

$lava: #c42021;
$green: #6da34d;
$orange: #ee9433;

.close-modal {
  position: relative;
  left: 95%;
  button {
    background-color: black;
    color: $background-white;
  }

  .error-message {
    margin-bottom: 13px;
    color: $lava;
    margin-left: 100px;
  }

  a {
    margin-top: 10px;
    color: black;
    text-decoration: none;
  }
}

textarea {
  resize: none;
}

.approvals-page-container {
  height: 90vh;
  width: 100%;
  justify-items: center;
  font-family: "roboto";
  margin-top: 100px;
  overflow-x: hidden;

  .header-wrapper {
    width: 100%;
    @include flex-center;
    position: relative;

    .approve-submissions-header {
      font-size: 40px;
      letter-spacing: 4px;
      text-align: center;
    }

    .external-links-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;
      position: absolute;
      right: 0px;
      width: 23.5%;
      max-width: 309px;
    }
  }

  .approvals-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "assigned search-inputs"
      "assigned search-results";
    width: 99%;
    height: 78vh;
    gap: 2rem;

    > :first-child {
      grid-area: assigned;
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .search-inputs-wrapper {
      grid-area: search-inputs;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2rem;
      padding: 2rem 0;

      .inputs {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 1.5rem;

        label {
          font-size: 16px;
          font-weight: 600;
          font-family: inherit;

          margin-bottom: 8px;
          text-transform: uppercase;
          letter-spacing: 0.5px;
        }

        input {
          width: 100%;
          max-width: 347px;
          height: 41px;
          text-indent: 10px;
          border-radius: 6px;
          font-size: 15px;
          transition: all 0.2s ease-in-out;

          &:focus {
            outline: none;
            border-color: #80bdff;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          }

          &::placeholder {
            color: #adb5bd;
          }
        }

        &.date-container {
          input[type="date"] {
            width: 100%;
            max-width: 347px;
            font-family: Roboto;
            cursor: pointer;
            color: #495057;

            &::-webkit-calendar-picker-indicator {
              background-image: url("../img/calendar.svg");
              margin-right: 2%;
              cursor: pointer;
              padding: 8px;
              opacity: 0.6;
              transition: opacity 0.2s ease-in-out;

              &:hover {
                opacity: 1;
              }
            }
          }
        }

        &.job-number-container {
          input {
            text-transform: uppercase;

            &::placeholder {
              text-transform: none;
            }
          }
        }
      }
    }

    > :last-child .job-group-container {
      grid-area: search-results;
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .date-input {
    width: 342px;
    font-family: inherit;
  }

  .job-group-container {
    width: 100%;
    height: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      text-align: center;
      font-weight: 600;
      font-size: 36px;
      line-height: 42px;
      letter-spacing: 0.14em;
      color: #000000;
      margin: 15px 0px;
    }

    .filter-search-wrapper {
      width: 99%;
      height: 600px;
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 25px;
    }
  }

  .job-group {
    margin-bottom: 2rem;

    .job-group-title {
      margin-bottom: 0.5rem;

      .job-number-wrapper {
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        background-color: lighten($color: #000000, $amount: 86%);
        padding: 0.5rem 1rem;
        border-radius: 10px;
        border: 1px solid #e0e0e0;

        .folder-icon {
          color: #666;
          font-size: 1.2rem;
        }

        .job-number {
          font-size: 1.1rem;
          font-weight: 600;
          color: #333;
        }
      }
    }

    .job-group-items {
      margin-left: 1rem;
    }
  }
}

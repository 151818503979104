.main-modal-container {
  @include flex-center;
  width: 100%;
  height: 100%;
  background-color: #d0d0d0;
  border: 1px solid black;
  border-radius: 10px;

  .inside-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-top: 15px;
    background-color: $background-white;
    width: 95%;
    height: 88%;
    border-radius: 10px;

    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      h1 {
        font-size: 24px;
        margin: 15px 0px 0px 30px;
      }
    }

    .select-email-wrapper {
      .email-select {
        padding: 10px;
        font-size: 16px;
        border-radius: 15px;
        width: 275px;
        appearance: none;
        background-image: url("../../img/drop-down-arrow.svg");
        background-repeat: no-repeat;
        background-position: right 0.7rem top 50%;
        background-size: 0.65rem auto;

        &:hover {
          cursor: pointer;
          background-color: #c9c1c1;
        }
      }
    }

    .buttons-wrapper {
      button {
        width: 110px;
        height: 40px;
        background-color: black;
        color: $background-white;
        margin: 20px;
        border: none;
        border-radius: 40px;
        text-align: center;
        font-size: 16px;
        box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.42);
        cursor: pointer;

        &:disabled {
          background-color: $light-grey;
        }
      }
    }
  }
}

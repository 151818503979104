@include checkbox-table;

tbody {
  width: 100%;
  background-color: white;

  .table-row {
    width: 100%;
    border: 1px solid black;

    .header {
      border: 1px solid transparent;
      text-align: left;
      color: black;
      padding: 10px;
      font-weight: bold;
      border: none;
      background-color: transparent;
    }

    .issue-type {
      width: unset;
    }

    .issue-date {
      width: 15%;
    }

    .issue-description {
      width: 35%;
      position: relative;
    }

    &:hover {
      background-color: #767676;
      color: white;

      svg {
        font-size: 16px;
        cursor: pointer;
      }
    }

    td {
      padding: 10px;
    }

    .issue-edit {
      padding: 0;
      color: #fff;
      font-size: 12px;
      width: 100px;

      button {
        height: 18px;
        width: 50px;
        background-color: transparent;
        font-size: 14px;
        border: 1px solid #fff;
        border-radius: 14px;
        color: #fff;
        cursor: pointer;
        display: inline;
      }
    }

    .issue-delete {
      width: 0px;
      font-size: 16px;
      color: white;
      padding-left: 5px;
    }
  }
}

.modal-container {
  @include flex-column-center;
  width: 100%;
  height: 100%;
  background-color: #d0d0d0;
  border: 1px solid black;
  border-radius: 10px;

  ::-webkit-scrollbar {
    width: 10px;
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #f1f1f1;

    &:active {
      background-color: #eaeaea;
    }
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #000000;

    &:active {
      background-color: #2e2e2e;
    }
  }

  .content-wrapper {
    @include flex-column-center;
    text-align: left;
    background-color: $background-white;
    width: 95%;
    height: 88%;
    border-radius: 10px;

    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      h1 {
        font-size: 24px;
        margin: 15px 0px 0px 30px;
      }

      .search-wrapper {
        position: relative;
        width: 35%;

        .magnifying-glass {
          position: absolute;
          left: 10%;
          top: 35%;
        }
        input {
          font-family: "Font Awesome\ 5 Free", Arial, Helvetica, sans-serif;
          height: 35px;
          border-radius: 15px;
          outline: none;
          padding: 0 5px 0 28px;
          margin: 10px 18px;
          width: 80%;
          border: 1px solid black;
        }
      }
    }

    hr {
      margin-top: 0;
      width: 95%;
      background-color: black;
      height: 1px;
      border: none;
    }

    .main-content-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 20px;
      width: 90%;
      height: 100%;
      margin: 20px auto 5px auto;
      row-gap: 16px;
      overflow-x: hidden;
      overflow-y: auto;

      .selectable-item-wrapper {
        color: grey;
        position: relative;
        display: block;
        width: 260px;
        font-size: 16px;
        cursor: pointer;

        &:hover::after {
          content: attr(data-title);
          padding: 5px;
          width: 90%;
          border: 1px solid #000;
          position: absolute;
          top: 40px;
          left: 0px;
          background: #383838;
          color: $background-white;
          z-index: 50;
        }

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .selected {
        color: black;
        text-decoration: underline;
      }
    }

    button {
      width: 150px;
      height: 50px;
      background-color: black;
      color: $background-white;
      margin: 10px auto;
      border: none;
      border-radius: 40px;
      text-align: center;
      font-size: 24px;
      box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.42);
      cursor: pointer;

      &:disabled {
        background-color: $light-grey;
      }
    }
  }
}

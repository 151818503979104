.report-phase-tables-wrapper {
  .verification-phase-code-wrapper {
    display: block;
    font-size: 18px;
    grid-template-columns: unset;
    margin-top: 30px;

    .break-phase-codes {
      margin: 0.7% 0 0 11.4%;
    }

    .section-content {
      display: grid;
      font-size: 18px;
      grid-template-columns: 100px auto;

      .phase-note {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1%;
      }

      .notes {
        margin-left: 0;
      }

      .table-notes {
        margin-left: 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .table {
        width: 91.5%;
        margin: 0 4%;
        padding: 0 20px;
        text-align: center;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
          font-size: 26px;
          margin: 0;
          text-decoration: 2px underline;
          margin-bottom: 10px;
        }

        table {
          width: 100%;
          background-color: $background-white;

          td {
            border: 1px solid gray;
          }

          th {
            border: 1px solid gray;
          }
        }
        .par-phase-total-labor {
          margin: 0 0 2% 0;
        }

        .par-phase-total-equipment {
          margin: 2% 0;
        }

        .break-phase-codes {
          width: 100%;
          margin: 0;
        }

        .header {
          font-size: 20px;
          font-weight: 500;
          padding-bottom: 10px;
          text-decoration: underline;
        }
      }

      .table-wrapper {
        display: flex;
        justify-content: center;
        min-width: 90vw;
        margin-left: 1em;
        overflow-x: auto;
        max-width: 90vw;

        .wages-container {
          font-family: sans-serif;
          margin: 50px 0 30px 0;
          width: 90%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          p {
            margin: 0;
            padding: 0;
          }

          div {
            margin: 0;
            padding: 0;
          }

          .wages-header {
            display: grid;
            grid-template-columns: 25vw 1fr 150px;
            gap: 10px;
            box-sizing: border-box;
            padding: 0 10px;
            white-space: nowrap;
            height: 1.75rem;
            width: 100%;
            align-items: center;
            justify-content: flex-start;
            font-size: 16px;
            font-weight: 900;
            border: 1px solid transparent;

            & > p {
              padding-left: 2px;
            }
          }

          .wages-table {
            display: grid;
            margin-bottom: 8px;
            width: 100%;

            ::-webkit-scrollbar {
              height: 4px;
              width: 8px;
            }

            ::-webkit-scrollbar-track {
              background-color: white;
            }

            ::-webkit-scrollbar-thumb {
              background-color: gray;
              height: 8px;
              border-radius: 5px;

              &:hover {
                cursor: pointer;
              }
            }

            .employee-data {
              display: grid;
              grid-template-columns: 25vw 1fr 150px;
              gap: 10px;
              box-sizing: border-box;
              margin-bottom: 6px;
              height: 1.75rem;
              width: 100%;
              align-items: center;
              justify-content: flex-start;
              padding: 0 10px;
              white-space: nowrap;
              background-color: #ffffff;
              font-size: 15px;
              font-weight: 500;
              border: 1px solid #000000;
              border-radius: 3px;

              .employee {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                margin-top: 4px;
                overflow-x: scroll;
                white-space: nowrap;

                .employee-id {
                  font-weight: 100;
                }
              }

              .employee-note {
                margin-top: 4px;
                margin-right: 10px;
                overflow-x: scroll;
                white-space: nowrap;
              }
            }

            .labor-data {
              display: grid;
              grid-template-columns: 50px 1fr 70px;
              align-items: center;
              box-sizing: border-box;
              height: 1.65rem;
              width: 100%;
              background-color: #ffffff;
              font-size: 12px;
              font-weight: 500;
              border: 1px solid #000000;
              border-radius: 3px;

              .class-header {
                padding-left: 10px;
                padding-right: 3px;
              }

              .class-name {
                margin-right: auto;
                width: fit-content;
                color: grey;
              }

              .class-hours-view {
                margin-right: 60px;
              }
            }
          }
        }

        table {
          width: 90%;
          background-color: white;
          font-size: 18px;
          text-align: center;
        }

        table,
        th,
        td {
          border: 1px solid black;
          border-collapse: collapse;
        }

        td {
          padding: 2px 10px;
        }
      }

      .par-phase-total {
        grid-column-start: 2;
        text-align: center;
      }
    }

    .empty-data-container {
      @include flex-center;
      width: 100%;
      margin-left: 4%;
      margin-top: 1%;
      flex-wrap: wrap;

      .no-phase-data {
        display: flex;
        justify-content: center;
        width: 175px;
      }

      svg {
        margin-right: 0.4%;
      }
    }
  }
}

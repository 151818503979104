@media (max-width: 1150px) {
  .external-link {
    @include flex-center;
    white-space: normal;
    width: 68%;
    max-width: 500px;
    height: 38px;
    font-size: 14px;
    font-weight: 500;
  }
}

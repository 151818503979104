@media (max-width: 800px) {
  .ReactModalPortal {
    .add-issue-modal-container {
      .header-wrapper {
        font-size: 14px;
        grid-template-columns: 85% 15%;
      }

      .header-graphic {
        transform: scaleX(-1) scale(47.5%);
        margin-bottom: -4.5px;
        margin-left: -112.5px;
      }

      .add-header {
        padding-left: 15px;
      }
    }

    .body-wrapper {
      .type-date-wrapper {
        .label-input-wrapper {
          width: 50%;

          .select-container {
            height: 52.5%;

            .type-input-invalid {
              height: 32px;
            }
          }

          .date-input-wrapper {
            display: flex;
            align-items: center;
            width: 100%;
            height: 59.5%;

            .date-input {
              display: flex;
              justify-content: space-around;
              align-items: center;
              box-sizing: border-box;
              height: 95%;
              min-width: 100%;
              text-align: left;
              font-family: "Roboto", sans-serif;
              color: black;
              border: 1px solid rgba(0, 0, 0, 0.75);

              &::-webkit-date-and-time-value {
                width: 100%;
                text-align: left;
              }
            }

            .invalid {
              &::-webkit-datetime-edit {
                color: lightgray;
              }
            }
          }
        }
      }

      .description-label-input-wrapper {
        .description-label {
          width: 81%;
        }

        .description-input-wrapper {
          .description-input {
            width: 90%;
          }
        }
      }

      .save-modal-button {
        width: fit-content;
      }
    }

    .modal-container {
      .content-wrapper {
        .header-container {
          h1 {
            margin: 10px 0px;
            height: 20px;
          }

          flex-direction: column;
          .search-wrapper {
            width: 100%;
            text-align: center;

            input {
              width: 60%;
            }

            .magnifying-glass {
              display: none;
            }
          }
        }

        .main-content-wrapper {
          grid-template-columns: 1fr;
          text-align: center;

          .selectable-item-wrapper {
            width: 65vw;
          }
        }

        .main-content-wrapper {
          .spinner {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow-y: scroll;
          }
        }
      }
    }

    .main-modal-container {
      .inside-container {
        height: 92%;

        .main-content-container {
          grid-template-columns: none;
          grid-auto-rows: none;
        }

        .main-content-container.spinner {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
        }

        .header-container {
          display: flex;
          flex-direction: column;

          h1 {
            font-size: 5vh;
            margin-left: 0px;
          }

          svg {
            display: none;
          }

          input {
            width: 70%;
            height: 7vh;
          }
        }
      }
    }

    .main-equipment-container {
      .equipment-container {
        h1 {
          margin: 0px;
        }

        .input-header-wrapper {
          display: flex;
          flex-direction: column;
          height: 5rem;

          h2 {
            margin: 0px;
          }

          svg {
            display: none;
          }

          input {
            height: 40px;
            width: 250px;
          }
        }

        .pagination-save-wrapper {
          .pagination-wrapper {
            margin-top: 0px;
          }
        }

        .equipment-data-wrapper {
          grid-template-columns: none;
          row-gap: 13px;
          height: 46%;

          .equipment-item-wrapper {
            border-bottom: none;
            padding: 10px;
          }
        }

        .equipment-data-wrapper.mobile {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .separator-center,
        .separator-right {
          display: none;
        }
      }
    }
  }

  .autofill-wrapper {
    height: 100%;

    .header-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 95%;
      height: 30%;

      .dropdown-container {
        width: 350px;
        right: 0;
      }

      button {
        width: 100%;
      }
    }

    .body-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 69%;
      width: 95%;
    }

    .hr {
      width: 95%;
      align-self: center;
      margin-left: 2vw;
    }
  }

  .reject-comments-container {
    h1 {
      margin-top: 4%;
      font-size: 29px;
    }
  }
}

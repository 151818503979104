.general-checkbox-wrapper {
  width: fit-content;
  height: fit-content;

  input[type="checkbox"] {
    transform: scale(1.15);
    width: 30px;
    height: 30px;
    margin: 0;
    visibility: hidden;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  input[type="checkbox"]:after,
  input[type="checkbox"]::after {
    content: " ";
    background-color: black;
    display: inline-block;
    font-weight: bold;
    font-size: 20px;
    width: 18px;
    height: 18px;
    visibility: visible;
    border-color: black;
    border-style: solid;
    border-radius: 5px;
    border-width: 4px 5px 6px 5px;
  }

  input[type="checkbox"]:checked:after,
  input[type="checkbox"]:checked::after {
    content: url(../../img/check-solid-cropped.svg);
  }

  input[type="checkbox"]:disabled:after,
  input[type="checkbox"]:disabled::after {
    background-color: #666666;
    border-color: #666666;
    filter: brightness(0.9);
  }
}

@media print {
  .general-checkbox-wrapper {
    opacity: 0;
  }
}

.horizontal-rule-wrapper {
  width: 100%;
  height: 100%;

  hr {
    height: 2px;
    width: 100%;
    background-color: #6d6d6d;
  }
}

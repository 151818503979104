table {
  .table-row {
    .edit {
      padding: 0;
      color: #fff;
      font-size: 12px;
      width: 100px;

      button {
        height: 18px;
        width: 50px;
        background-color: transparent;
        font-size: 14px;
        border: 1px solid #fff;
        border-radius: 14px;
        color: #fff;
        cursor: pointer;
        display: inline;
      }
    }

    .delete {
      width: 0px;
      font-size: 16px;
      color: white;
      padding-left: 5px;
    }
  }
}

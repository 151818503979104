.verification-page-container {
  padding: 30px 50px 0px 0px;
  display: grid;
  background-color: #d4d5d5;
  overflow: hidden;
  background-image: linear-gradient(
    to right,
    #a8a5a5,
    #a8a5a5 100px,
    transparent 100px
  );

  h1 {
    font-size: 22px;
    font-weight: 500;
  }

  .verification-hr-wrapper {
    display: grid;
    grid-template-columns: 115px auto;

    .verification-hr {
      grid-column: 2;
      width: 98%;
    }
  }

  .toast-container {
    text-align: center;
    position: fixed;
    width: 400px;
    bottom: 15px;
    left: 15px;
    display: flex;
    background-color: #cc0000;
    align-items: center;
    justify-content: center;
    opacity: 0.75;
    border-radius: 10px;
    color: $background-white;
  }

  .incidents-and-injuries-wrapper {
    display: grid;
    font-size: 18px;
    grid-template-areas:
      "a b"
      "a c";
    grid-template-columns: 100px auto;

    .general-checkbox-wrapper {
      grid-area: a;
    }

    .report-notes-wrapper {
      display: block;

      .section-content {
        display: grid;
        font-size: 18px;
        grid-template-columns: 250px auto;
        padding: 10px 0;
        margin-left: calc(4% + 20px);
      }
    }

    .injuries-notes {
      grid-area: b;
    }

    .incidents-notes {
      grid-area: c;
    }
  }

  .confidential-msg {
    display: flex;
    justify-content: center;
    margin: 10px 0px;
    padding-left: 90px;
  }

  .confirm {
    margin-bottom: 125px;
  }

  .verification-checkbox-wrapper {
    @include flex-center;
    width: 100%;
    height: 100%;
  }

  .hidden {
    visibility: hidden;
  }

  .phase-code {
    align-items: flex-start;
  }

  .reject-message-text {
    margin-left: 100px;
    width: 100%;
    @include flex-column-center;
    overflow: clip;
    position: fixed;
    bottom: 0;

    label {
      box-sizing: border-box;
      display: block;
      text-align: center;
      background-color: #721313;
      color: #fff;
      width: calc(100vw - 11px);
      padding: 0.5% 0;
      text-indent: -100px;
    }

    textarea {
      width: calc(100vw - 12em);
      overflow: hidden;
      padding-left: 2em;
      padding-right: 9em;
      height: 7em;
    }
  }

  .verification-approvals-wrapper {
    display: grid;
    grid-template-columns: 100px auto;

    table {
      grid-column: 2;
    }
  }

  &.select-print {
    .action-buttons-wrapper {
      .cancel {
        min-width: 100px;
      }

      .print {
        min-width: 148px;
      }
    }
  }
}

.blur {
  filter: blur(30px) invert(49%) sepia(3%) saturate(7%) hue-rotate(31deg)
    brightness(102%) contrast(93%);
  -webkit-filter: blur(30px) invert(49%) sepia(3%) saturate(7%)
    hue-rotate(31deg) brightness(102%) contrast(93%);
  -moz-filter: blur(30px) invert(49%) sepia(3%) saturate(7%) hue-rotate(31deg)
    brightness(102%) contrast(93%);
  -o-filter: blur(30px) invert(49%) sepia(3%) saturate(7%) hue-rotate(31deg)
    brightness(102%) contrast(93%);
  -ms-filter: blur(30px) invert(49%) sepia(3%) saturate(7%) hue-rotate(31deg)
    brightness(102%) contrast(93%);
}

.approval-verification {
  background-color: #d4d5d5;
}

.select-print {
  background-color: white;
}

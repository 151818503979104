.time-materials-table {
  width: 100%;

  thead {
    tr {
      th {
        padding: 10px;
      }
    }
  }

  tbody {
    height: 60px;
    text-align: left;
    border-collapse: collapse;
    background-color: white;

    tr {
      background-color: white;
      border: 1px solid black;

      td {
        padding: 10px;

        .time-material-input {
          input {
            width: 75%;
          }
        }
      }
    }
  }
}

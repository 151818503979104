@include checkbox-table;

tbody {
  width: 100%;
  background-color: white;

  .table-row {
    width: 100%;
    border: 1px solid black;

    .subcontractor-header {
      border: 1px solid transparent;
      text-align: left;
      color: black;
      padding: 10px;
      font-weight: bold;
      font-size: medium;
      border: none;
      background-color: transparent;
    }

    svg {
      color: white;
      font-size: 16px;
      cursor: pointer;
    }

    &:hover {
      background-color: #767676;
      color: white;
    }

    .sub-name {
      input {
        width: 190px;
      }
    }

    td {
      padding: 10px;

      .default-value {
        color: grey;
      }

      .input-sub-details {
        width: 200px;
      }

      .shift-duration {
        width: 200px;
      }
    }
  }
}

.subcontractor-buttons-wrapper {
  display: flex;

  .subcontractor-button {
    height: 40px;
    width: 35vw;
    min-width: 250px;
    background-color: #767676;
    color: white;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin: 1vw;
  }
}

.report-details-wrapper {
  display: grid;
  position: relative;
  font-size: 18px;
  grid-template-columns: 100px auto;

  .section-content {
    margin-left: calc(4% + 20px);
    .upper-content,
    .lower-content {
      width: 81%;
      display: grid;
      grid-template-columns: 50% 50%;

      .section-grid {
        display: grid;
        grid-template-rows: auto;

        .grid-row {
          display: grid;
          grid-template-columns: 120px auto;

          .data-label {
            font-weight: 600;
          }
        }
      }
    }

    .lower-content {
      display: flex;
      flex-direction: row;
      margin-top: 1%;
    }
  }

  .daily-ticket-container {
    position: absolute;
    right: 3.5%;
    bottom: 2%;
    text-align: center;
    border: 2px solid black;
    border-radius: 5px;
    width: 121px;
    background-color: #a8a5a5;
    padding-bottom: 15px;

    .container {
      width: 100%;
    }
  }
}

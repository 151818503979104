.report-notes-wrapper {
  display: grid;
  font-size: 18px;
  grid-template-columns: 100px auto;

  .section-content {
    grid-column: 2;
    display: grid;
    font-size: 18px;
    grid-template-columns: 250px auto;
    padding: 10px 0;
    margin-left: calc(4% + 20px);

    .label {
      font-size: 19px;
      font-weight: 500;
      width: 300px;
      margin: 7px 0px 7px 0;
      white-space: nowrap;
    }

    .details {
      font-weight: 400;
      width: 75%;
      margin: 7px 0px 7px 0;
      white-space: pre-line;
    }
  }
}

.hrvc-form-container {
  width: 100%;
  height: 100%;

  .header-bar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: black;
    border-radius: 15px 15px 0 0;

    .header-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      gap: 20%;
    }

    .header-title {
      color: #fff;
      font-size: 24px;
      margin: 8px 0px;
      text-wrap: nowrap;
    }
  }

  .header-graphic {
    transform-origin: bottom;
    transform: scaleX(-1) scale(78%);
    image-rendering: auto;
    margin-right: 22%;
    margin-top: -3.1%;
    z-index: 5;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }

  .close-button {
    position: absolute;
    border: none;
    text-decoration: none;
    color: gray;
    background-color: transparent;
    margin-left: 92%;
    margin-top: -31px;

    &:hover {
      transition: 0.2s ease-in-out;
      cursor: pointer;
      color: #fff;
    }
  }

  .information-wrapper {
    display: grid;
    grid-template-columns: 35% 65%;
    background-color: #d9d9d9;
    height: 84%;
    padding: 0;

    .left-side-container {
      border-right: 3px solid #a3a3a3;
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 67vh;
      max-height: 505px;
      overflow-y: auto;

      .left-side-header {
        font-size: 14px;
        font-weight: 600;
        margin: 15px;
      }

      .hrvc-date {
        display: flex;
        flex-direction: column;
        width: 90%;

        label {
          font-size: 14px;
          margin-bottom: 5px;
        }

        input {
          margin-bottom: 10px;

          &:disabled {
            background-color: transparent;
          }
        }
      }

      .select-employee-wrapper {
        display: flex;
        justify-content: center;
        width: 90%;

        .hrvc-employee-name {
          label {
            font-size: 14px;
            margin-bottom: 5px;
          }

          input {
            margin-bottom: 10px;
            width: 93%;
            cursor: default;
          }
        }

        .left-side-btn {
          background-color: #767676;
          color: white;
          border: 1px solid #383838;
          width: 80px;
          border-radius: 15px;
          transition: 0.2s ease-in-out;
          text-align: center;
          text-decoration: none;
          padding: 2px 0;
          font-weight: unset;
          position: relative;
          top: 38%;
          height: 20px;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .gom-paragraph {
        font-size: 14px;
        margin-top: 10px;
        width: 90%;

        .gom-information {
          margin: 3% 0;
        }

        strong {
          font-size: 16px;
        }
      }
    }

    .right-side-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      overflow-y: auto;

      .right-side-header {
        font-size: 14px;
        font-weight: 600;
        margin: 15px;
      }

      .hrvc-wrapper {
        width: 90%;

        .hrvc-header {
          font-size: 14px;
          margin-bottom: 5px;
        }

        .behavior {
          margin-bottom: 10px;
        }

        textarea {
          width: 100%;
          margin-bottom: 10px;
          font-family: "roboto";
        }
      }
    }
  }

  .save-btn-wrapper {
    position: relative;
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #383838;
    border-radius: 0 0 15px 15px;
    z-index: 1;

    button {
      background: #fff;
      border-radius: 15px;
      font-size: 14px;
      font-weight: 600;
      width: 100px;
      height: 30px;
      text-decoration: none;
      border: none;
      transition: 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        background-color: lightgray;
      }
    }
  }
}

.report-header-wrapper {
  display: grid;
  grid-template-columns: 135px auto;

  .header-content {
    grid-column: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;

    .letter-head {
      margin: 2% 0 -3.5% 0;
    }

    .header {
      font-size: 26px;
      font-weight: 500;
      margin: 20px 0;
      white-space: pre-line;
    }

    .sub-header {
      margin: 0px;
      font-size: 16px;
      font-weight: 400;
    }

    .show-print {
      display: none;
    }
  }
}

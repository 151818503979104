@media (max-width: 1150px) {
  .approvals-page-container {
    display: flex;
    flex-direction: column;

    .header-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .approve-submissions-header {
        font-size: 4vw;
      }

      .external-links-wrapper {
        @include flex-column-center;
        gap: 50px;
        margin: 25px 0 25px 2.5%;
        position: unset;
        width: 100%;
        max-width: unset;
      }
    }

    .approvals-wrapper {
      flex-direction: column;
      align-items: center;
      display: flex;
      margin: auto;
      height: auto;
      gap: 2rem;

      .search-inputs-wrapper {
        width: 75%;
        margin: 0 auto;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .inputs {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          label {
            align-self: center;
            margin-left: 0;
          }

          input {
            width: 100%;
            max-width: 347px;
          }
        }
      }

      .job-group-container {
        width: 75%;
        height: auto;

        .filter-search-wrapper {
          height: 400px;
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .approvals-page-container {
    margin-top: 125px;
    .approvals-wrapper {
      .job-group-container {
        width: 90%;
      }
    }
  }
}

// media query for mobile

@media (max-width: 768px) {
  .approvals-page-container {
    margin-top: 150px;

    .header-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .approve-submissions-header {
        font-size: 4vw;
      }

      .external-links-wrapper {
        @include flex-column-center;
        gap: 50px;
        margin: 25px 0 25px 2.5%;
        position: unset;
        width: 100%;
        max-width: unset;
      }
    }

    .approvals-wrapper {
      .job-group-container {
        width: 90%;

        .filter-search-wrapper {
          width: 100%;

          .par-notification-container {
            grid-template-columns: 90px 150px auto 40px;
            gap: 0.25rem;
            padding: 0 0.25rem;

            .date-info {
              gap: 0.25rem;
            }

            .submitter-info {
              .submitter-name {
                font-size: 14px;
              }
            }

            .approve-header {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .approvals-page-container {
    .approvals-wrapper {
      .job-group-container {
        .filter-search-wrapper {
          .par-notification-container {
            grid-template-columns: 90px 100px auto 15px;
          }
        }
      }
    }
  }
}

.modal-container {
  .content-wrapper {
    .loading-wrapper {
      @include flex-center;
      height: 100%;
      width: 95%;
      grid-template-columns: 1fr;
      overflow: hidden;

      .loading-spinner-container {
        svg {
          @include flex-center;
          font-size: 100px;
        }
      }
    }

    button {
      &:disabled {
        cursor: default;
      }
    }
  }
}

@media (max-width: 430px) {
  .loading-spinner-container {
    margin-top: 0;
  }
}

.phase-note-container {
  margin: 20px 0 0 0;

  .collapse-button {
    height: 35.5px;
    border: none;
    background-color: black;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-image: url("../../../img/md-yellow-design.svg");
    cursor: pointer;
  }

  .single-problem-container {
    padding-top: 0;

    .note-wrapper {
      .phase-notes {
        display: flex;
        justify-content: center;
        margin: 20px 0 0 0;

        .hidden {
          display: none;
        }

        textarea {
          width: 97%;
          background-color: white;
          text-align: left;
          cursor: text;
        }
      }
    }
  }
}

.weather-information-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .get-weather-btn {
    color: white;
    background-color: grey;
    cursor: pointer;
    border: none;
    font-size: 16px;
    padding: 4px;
    width: 301px;
    align-self: flex-end;

    .fa-spinner {
      animation-name: spin;
      animation-duration: 5000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      min-width: 20px;
    }

    .fa-cloud-sun-rain {
      min-width: 20px;
    }
  }

  .forecast-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    label {
      margin-right: 8px;
    }

    textarea {
      font-family: Arial, Helvetica, sans-serif;
      box-sizing: border-box;
      width: 301px;
      height: 46px;
      padding: 6px 7px;
    }
  }
}

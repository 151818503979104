.show-hide-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  button {
    height: 50px;
    width: 100%;
    background-color: #383838;
    color: white;
    font-size: 28px;
    cursor: pointer;
  }
}

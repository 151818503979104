.external-link {
  @include flex-center;
  border: 2px solid black;
  text-decoration: none;
  color: black;
  white-space: pre-line;
  text-align: left;
  font-size: 16px;
  width: 115px;
  height: 48px;
  transition: opacity 0.2s ease-in;

  &:hover {
    opacity: 0.7;
  }
}

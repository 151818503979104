.par-notification-container {
  width: 96%;
  display: grid;
  grid-template-columns: 110px 200px auto 40px;
  align-items: center;
  justify-items: center;
  gap: 0.5rem;
  margin-bottom: 10px;
  height: 50px;
  border: 1px solid black;
  padding: 0 0.5rem;

  &.blue-gradient {
    @include blue-gradient;
  }

  &.green-gradient {
    @include green-gradient;
  }

  .date-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;

    .date {
      font-weight: 600;
      white-space: nowrap;
    }
  }

  .submitter-info {
    width: 100%;
    text-align: center;

    .submitter-name {
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      padding: 0 0.5rem;
    }
  }

  .approve-header {
    color: black;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    text-align: center;
  }

  .delete-icon-wrapper {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    .delete-icon {
      color: #000000;
      cursor: pointer;
      width: 16px;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

@media (max-width: 650px) {
  .report-header-wrapper {
    display: block;
    text-align: center;

    .header-content {
      text-align: center;
      align-self: center;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-top: 10px;
      background-color: transparent;

      .letter-head {
        display: none;
      }
    }
  }
}

@media print {
  .report-header-wrapper {
    grid-template-columns: 0px auto;
    .header-content {
      .letter-head {
        visibility: visible;
        width: 100%;
        min-width: 90vw;
        padding-left: 10px;
      }

      .main-header {
        display: none;
      }

      .show-print {
        display: block;
      }

      .sub-header {
        display: none;
      }
    }
  }
}

@media (max-width: 650px) {
  .report-signatures-wrapper {
    display: block;

    .show-mobile {
      display: block;
      text-align: center;
      margin: -30px 0 0 4%;
    }

    .signatures {
      display: none;
    }
  }
}

@media print {
  .report-signatures-wrapper {
    @include flex-center;
    width: 100%;
    grid-template-columns: 300px auto;
    margin-left: 10px;

    .general-checkbox-wrapper {
      display: none;
    }

    .signatures {
      grid-template-columns: 100px auto;
      @include flex-center;
      width: 100%;

      .group {
        .label {
          padding: 0;
        }
      }
    }
  }
}

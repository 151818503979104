@media (max-width: 650px) {
  .verification-totals-wrapper {
    display: block;

    .tables {
      width: 96vw;
      display: flex;
      flex-direction: column;
      align-items: center;

      .requested-changes {
        width: 94%;
        margin: 20px 0px 20px 0.5%;
      }

      .outer-wrapper {
        width: 100%;
        overflow-x: scroll;
        max-width: 90vw;

        .table-wrapper {
          min-width: 90vw;
          margin: 0px;
          padding: 0px;
          box-shadow: 0px 0px 0px 2px black inset;
          overflow-x: auto;
          justify-content: flex-start;

          table {
            table-layout: fixed;
            border-collapse: separate;
            border-spacing: 0;
            width: fit-content;
            min-width: unset;
            max-width: fit-content;

            tbody {
              tr:nth-child(odd) {
                background-color: white;

                .vertical-header {
                  background-color: white;
                }
              }

              tr:nth-child(even) {
                background-color: #dee2e6;

                .vertical-header {
                  background-color: #dee2e6;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media print {
  .verification-totals-wrapper {
    display: grid;
    font-size: 18px;
    grid-template-columns: 0px auto;
    width: 100vw;

    h1 {
      text-align: center;
      font-size: 26px;
      text-decoration: underline;
    }

    .tables {
      grid-column: 2;
      justify-self: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin-right: 5px;

      .requested-changes {
        margin: 0 auto;
        th {
          color: black;
          font-weight: 500;
        }
      }

      .outer-wrapper {
        justify-self: center;
        display: flex;
        justify-content: center;
        width: 100%;
        overflow-x: unset;
        margin: 0px;

        .table-wrapper {
          justify-self: center;
          display: flex;
          justify-content: center;
          width: 90%;
          overflow-x: unset;
          margin: 0px;

          table {
            width: 100%;
            min-width: 100%;
            table-layout: auto;
            overflow-x: unset;
            border-collapse: collapse;
            margin: 0px;

            tbody {
              tr:nth-child(even) {
                background-color: #dee2e6;
              }

              tr:nth-child(odd) {
                background-color: white;
              }
            }
          }
        }
      }

      .header {
        font-size: 20px;
        font-weight: 500;
      }
    }

    .par-phase-total,
    .par-phase-total-equipment {
      text-align: center;
      font-size: 22px;
    }

    .table-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-left: 0.5em;
      overflow-x: auto;
      max-width: 90vw;

      table {
        width: 90%;
        min-width: 700px;
        background-color: white;
        font-size: 18px;
        text-align: center;
      }

      table,
      th,
      td {
        border: 1px solid black;
        border-collapse: collapse;
      }

      td {
        padding: 2px 10px;
      }
    }

    table.requested-changes {
      width: 88%;
      margin: 20px 0px 20px 10px;
    }
  }
}

@media (max-width: 650px) {
  .par-page-container {
    .verification-totals-wrapper {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;

      .tables {
        width: 100%;

        .outer-wrapper {
          .table-wrapper {
            min-width: unset;

            table {
              width: 100%;
              min-width: unset;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .par-page-container {
    .verification-totals-wrapper {
      padding: 0 10px;

      h1 {
        font-size: 22px;
      }

      .tables {
        .par-phase-total,
        .par-phase-total-equipment {
          font-size: 18px;
        }
      }
    }
  }
}

.report-signatures-wrapper {
  display: grid;
  font-size: 18px;
  grid-template-columns: 100px auto;
  width: 100%;

  .signatures {
    @include flex-center;
    width: 100%;

    .group {
      display: flex;
      margin: 90px;
      gap: 30px;
      justify-content: center;
      flex-direction: column;

      .signature-container {
        display: flex;
      }
    }
  }

  .show-mobile {
    display: none;
  }
}

@media (max-width: 962px) {
  .navbar-wrapper {
    .bhi-logo-container {
      .user-name {
        padding-top: 5%;
        letter-spacing: normal;
      }

      .logo-wrapper {
        img {
          height: 72px;
        }
      }
    }
  }
}

@media (max-width: 845px) {
  .navbar-wrapper {
    .bhi-logo-container {
      margin-right: 0;
      width: 200px;
      white-space: nowrap;

      .user-name {
        margin-top: 10%;
        margin-right: 0;
        width: 100%;
        text-overflow: ellipsis;
      }
    }

    .hamburger-menu-container {
      .hamburger-menu {
        display: flex;
        position: relative;
        right: 30px;
        width: 30px;
        height: 30px;
        border: none;
        background-image: url(../../img/white-hamburger.svg);
        filter: invert(1);
        background-repeat: no-repeat;
      }

      .navigation-menu {
        display: none;
      }

      .expanded {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100px;
        right: 0px;
        width: 100%;
        background-image: url(../../img/nav-bar-background.svg);
        text-align: center;

        a {
          margin: 0px;
        }
      }
    }
  }
}

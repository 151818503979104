@media (max-width: 650px) {
  .report-details-wrapper {
    grid-template-columns: auto;

    .section-content {
      margin-left: 5%;
      display: flex;
      flex-direction: column;
      max-width: 90vw;

      .upper-content {
        display: block;

        .section-grid {
          .grid-row {
            .precipitation-data {
              width: 49%;
            }
            .wind-data {
              width: 49%;
            }
          }
        }
      }
    }

    .daily-ticket-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      bottom: unset;
      top: 10%;
      right: 5%;
    }
  }
}

@media print {
  .report-details-wrapper {
    margin-top: 15px;

    .section-content {
      width: 100%;
      margin: 0;
      grid-column: 2;

      .upper-content {
        width: 100%;
        display: flex;

        .section-grid {
          width: 50%;

          .precipitation-data {
            width: unset;
          }

          .wind-data {
            width: unset;
          }
        }
      }
      .lower-content {
        width: 100%;
      }
    }
  }
}

.notification-wrapper {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 300px;
}

.notification-item {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  animation: SlideLeft 0.2s;
  animation-fill-mode: forwards;
  width: 300px;
}

@keyframes SlideLeft {
  0% {
    margin-left: 120%;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes SlideRight {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: 120%;
  }
}

.notification-item.exit {
  animation: SlideRight 0.2s;
  animation-fill-mode: forwards;
}

.notification-item p {
  margin: 0;
  padding: 10px;
  overflow: hidden;
}

.notification-item .bar {
  height: 10px;
}

.Toastify__toast--success {
  background-color: rgb(2, 185, 42);
  .fas {
    padding-right: 10px;
  }
}

.Toastify__toast--error {
  background-color: #ce3635;
  .fas {
    padding-right: 10px;
  }
}

.Toastify__toast--warning {
  background-color: #fdd656;
  .fas {
    padding-right: 10px;
  }
}

.Toastify__toast-body {
  white-space: pre-line;

  a {
    color: white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      opacity: 0.9;
    }
  }
}

.toast-link {
  margin-top: 8px;
  padding: 4px 0;

  a {
    display: block;
    padding: 4px 8px;
    border-radius: 4px;
    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

.toast-with-link {
  .Toastify__toast-body {
    white-space: pre-line;
  }

  a.toast-link {
    display: inline-block;
    color: white;
    text-decoration: underline;
    padding: 4px 8px;
    margin-top: 8px;
    border-radius: 4px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      text-decoration: none;
    }
  }
}

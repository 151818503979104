.reject-comments-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: darken($color: white, $amount: 20%);

  svg {
    font-size: 30px;
    align-self: flex-end;
    margin: 4% 4% 0 0;
  }
  h1 {
    margin-top: -2.5%;
  }
  .reject-comments-wrapper {
    width: 100%;

    hr {
      width: 90%;
    }

    .reject-comments-info {
      overflow: auto;
      display: flex;

      .status-wrapper {
        margin-left: 5%;
        .status-date {
          opacity: 0.5;
        }
      }

      .reject-comments {
        margin-left: 5%;
      }
    }
  }
}

.phase-code-sub-section-container {
  display: flex;
  justify-content: center;
  margin: 20px 0 0 0;

  .checkbox-item-container {
    .show-hide-wrapper {
      .collapse-button {
        height: 35.5px;
        border: none;
        background-color: black;
        background-repeat: no-repeat;
        background-position: bottom right;
        background-image: url("../../../img/md-yellow-design.svg");
        cursor: pointer;
      }

      .table-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 98%;

        table {
          width: 100%;

          .table-row {
            border: 1px solid black;

            .padding-20 {
              padding: 0 15px;
            }

            td {
              input {
                text-indent: 3px;
              }

              input.operated-hours {
                width: 139px;
              }

              input.employee-hours {
                width: 139px;
              }

              .checkmark-small {
                border-radius: 5px;
                border: 4px black solid;
                cursor: pointer;
              }
            }

            .checkbox-container {
              @include flex-center;
            }

            &:hover {
              color: white;
              background-color: #767676;
            }
          }
        }

        .mobile-view {
          max-width: 400px;

          thead {
            display: none;
          }

          tbody {
            .table-row {
              display: flex;
              flex-direction: column;

              td {
                display: flex;
                height: 20px;
                align-items: center;
                justify-content: space-evenly;

                label {
                  display: none;
                }

                p {
                  width: 40%;
                }

                input {
                  width: 45%;
                }

                input[type="checkbox"]::after {
                  position: absolute;
                  left: 39%;
                }
              }

              .padding-20 {
                padding-top: 10px;
                display: block;
                text-wrap: nowrap;
                width: 75vw;
                max-width: 365px;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
              }

              svg {
                color: #000000;
              }
            }
          }
        }

        .wages-container {
          display: flex;
          flex-direction: column;
          font-family: sans-serif;
          margin: 30px 0;

          .wages-header {
            height: 30px;
            display: flex;
            align-items: center;
            font-weight: 900;

            .header-employee {
              padding-left: 10px;
              width: 313px;
            }

            .header-note {
              margin-right: auto;
            }

            .header-hours {
              margin-right: 177px;
            }
          }

          .wages-table {
            margin-bottom: 8px;

            .employee-data {
              width: 82vw;
              height: 30px;
              display: flex;
              align-items: center;
              background-color: #ffffff;
              font-size: 15px;
              margin-bottom: 6px;
              border: 1px solid #000000;
              border-radius: 3px;

              .employee {
                width: 320px;
                display: flex;

                .employee-name {
                  margin-right: 5px;
                  padding-left: 10px;
                  text-wrap: nowrap;
                }

                .employee-id {
                  margin-right: 19px;
                  font-weight: 100;
                }
              }

              .employee-note {
                margin-right: auto;
                margin-left: 3px;
                width: 150px;
                text-indent: 3px;
              }

              .employee-hours {
                text-align: center;
                margin-right: 42px;
                width: 125px;
              }

              .labor-buttons {
                min-width: 135px;

                .add-work {
                  margin-right: 10px;
                  padding: 4px 12px;
                  text-wrap: nowrap;
                  width: fit-content;
                  color: #ffffff;
                  background-color: #383838;
                  border: none;
                  border-radius: 20px;
                  font-size: 15px;
                  height: unset;
                }

                .remove-employee {
                  margin-right: 8px;
                  color: #383838;
                  background-color: transparent;
                  border: none;
                  font-size: 16px;
                  width: unset;
                }
              }
            }

            .labor-data {
              height: 30px;
              display: flex;
              align-items: center;
              font-size: 12px;
              border: 1px solid #000000;
              border-radius: 3px;
              background-color: #e5e5e5;

              .class-header {
                padding: 0 10px 0 3px;
              }

              .class-name {
                margin-right: auto;
              }

              .class-hours {
                width: 85px;
                margin-right: 155px;
              }

              .remove-work {
                margin-right: 10px;
                color: #383838;
                background-color: transparent;
                border: none;
                font-size: 11px;
                width: unset;
              }
            }
          }
        }

        .mobile-view {
          .wages-header {
            display: none;
          }

          .wages-table {
            .employee-data {
              flex-direction: column;
              height: fit-content;
              max-width: 400px;

              .employee {
                max-width: 82vw;
                padding-left: 20px;
              }

              .employee-name {
                width: 50%;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .employee-note {
                margin-right: 3px;
                width: 75vw;
                max-width: 290px;
              }
            }

            .labor-data {
              width: 82vw;
              max-width: 400px;
              margin: auto;

              .class-name {
                text-wrap: nowrap;
                width: 50%;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .class-hours {
                width: 35px;
                margin-right: 5px;
              }
            }
          }
        }

        .labor-table,
        .equipment-table,
        .progress-entry-table {
          th {
            font-size: 16px;
            background-color: #ccc;
            border: 1px solid #ccc;
            border-bottom: 1px solid black;
            padding-bottom: 5px;
          }
        }
      }
    }
  }
}

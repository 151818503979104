@media (max-width: 650px) {
  .report-buttons-wrapper {
    display: flex;
    justify-content: center;
    padding-left: 20px;

    .buttons {
      display: flex;
      flex-flow: row wrap;
      max-width: 55vw;
    }

    .break-wrapper {
      display: none;
    }
  }
}

@media print {
  .report-buttons-wrapper {
    visibility: hidden;
  }
}

@media (max-width: 400px) {
  .main-modal-container {
    .inside-container {
      .select-email-wrapper {
        .email-select {
          width: 70vw;
        }
      }
      .buttons-wrapper {
        @include flex-column-center;
      }
    }
  }
}

.modal-container {
  height: 100%;
  width: 100%;

  .autofill-wrapper {
    margin-top: 4%;
    height: 100%;
    width: 95%;
    background-color: #d0d0d0;
    border-radius: 10px;

    ::-webkit-scrollbar {
      width: 10px;
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #f1f1f1;

      &:active {
        background-color: #eaeaea;
      }
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #000000;

      &:active {
        background-color: #2e2e2e;
      }
    }

    .header-wrapper {
      height: 15%;
      background-color: $background-white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px 10px 0px 0px;

      .autofill {
        font-weight: 550;
        padding-left: 2%;
        font-size: 1.5em;
        margin: 6px 18px;
      }

      .date-phase-button {
        width: 284px;
        height: 36px;
        border: 1px solid black;
        border-radius: 15px;
        background-color: transparent;
        color: black;
        font-weight: 150;
        margin: 15px 50px;
        font-size: 1.25em;
        cursor: pointer;
      }

      .dropdown-container {
        position: absolute;
        background-color: #c4c4c4;
        width: 450px;
        height: 401px;
        border-radius: 10px;
        right: 75px;
        bottom: 112px;
        overflow-y: auto;

        .date-container {
          .header-date {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            margin-top: 15px;
            margin-bottom: 10px;

            h3 {
              margin-left: 25px;
              font-size: 1.35em;
              font-weight: 350;
              cursor: pointer;
            }

            svg {
              margin-right: 15px;
            }
          }
        }

        .phases-container {
          margin-left: 45px;
          font-size: 20px;
          letter-spacing: 1.5px;
          font-family: "roboto";
          font-weight: 300;
        }

        .hide-phases-container {
          display: none;
        }
      }

      .loading-spinner-container {
        @include flex-center;
        margin-top: 32%;
        font-size: 100px;
      }
    }

    .hr {
      width: 100%;
      margin: auto;
      background-color: $background-white;
      border: 0.25px solid black;
    }

    .autofill-body-wrapper {
      @include flex-column-center;
      justify-content: flex-start;
      background-color: $background-white;
      height: 80%;
      border-radius: 0px 0px 10px 10px;
      font-size: 24px;
      font-weight: 400;
      overflow-y: auto;

      .date-select {
        font-weight: 500;
        font-size: 20px;
      }

      .phase-codes {
        margin: 40px 0 35px 0;
      }

      .prompt-wrapper {
        .autofill-phase {
          font-size: 18px;
          font-weight: 200;
          text-align: center;
        }

        .prompt {
          margin-top: 40px;
          font-size: 20px;
          font-weight: 200;
          text-align: center;
        }
      }

      .buttons-wrapper {
        @include flex-center;
        margin-top: 20px;

        button {
          width: 155px;
          height: 28px;
          background-color: transparent;
          color: black;
          font-weight: 150;
          margin: 15px 40px;
          font-size: 24px;
          border: none;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

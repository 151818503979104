@media (max-width: 650px) {
  .report-table-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0px 0px;
    height: fit-content;

    .verification-checkbox-wrapper {
      margin-top: 30px;
    }

    .table-wrapper {
      display: unset;
      background-color: white;
      box-shadow: 0px 0px 0px 2px black inset;
      margin-left: 4vw;

      table {
        table-layout: fixed;
        border-collapse: separate;
        border-spacing: 0;
        width: fit-content;
        max-width: fit-content;
        min-width: unset;

        tbody {
          tr {
            .vertical-header {
              position: sticky;
              top: 0;
              left: 0;
              background-color: white;
              z-index: 0;
              width: 35px;
              vertical-align: bottom;
              text-align: center;
              padding: 10px 5px;
              border-bottom: 1px solid black;
              border-right: 1px solid black;
              vertical-align: middle;

              span {
                -ms-writing-mode: tb-rl;
                -webkit-writing-mode: vertical-rl;
                writing-mode: vertical-rl;
                transform: rotate(180deg);
                white-space: nowrap;
              }
            }

            td {
              width: 200px;
              border-bottom: 1px solid black;
              border-right: 1px solid black;
              padding: 2px 10px;
              hyphens: auto;
            }

            .first-column {
              border-left: 1px solid black;
            }
          }
        }
      }
    }
  }
}

@media print {
  .report-table-wrapper {
    display: flex;
    grid-template-columns: unset;
    flex-direction: column;
    align-items: center;
    margin: 5px 0;
    width: 99.5vw;
    min-width: 99.5vw;

    .table-wrapper {
      display: flex;
      justify-content: center;
      width: 88.5%;
      min-width: 88.5%;
      margin: 0px 0px 10px 0px;
      overflow-x: unset;
      max-width: unset;
      box-shadow: none;

      table {
        width: 100%;
        min-width: 100%;
        background-color: white;
        font-size: 18px;
        text-align: center;
        table-layout: auto;

        .issue-type,
        .date-raised,
        .vc-employee-name,
        .vc-employee-number {
          white-space: nowrap;
        }
      }

      table,
      th,
      td {
        border: 1px solid black;
        border-collapse: collapse;
      }

      td {
        padding: 2px 10px;
      }
    }
  }
}

@include new-par-query(1310px) {
  .time-materials-container {
    .time-materials-content-container {
      grid-template-columns: 50% 50%;
    }
  }
}

@include new-par-query(1212px) {
  .job-information {
    width: 100%;
  }
}

@include new-par-query(1180px) {
  .time-materials-container {
    .time-materials-content-container {
      display: block;
      margin: 20px auto;

      .job-information-wrapper {
        width: 70%;
        gap: 0;
      }
    }
  }
}

@media (max-width: 1045px) {
  .par-page-container {
    .par-information-container {
      .main-content-container {
        display: flex;
        flex-direction: column;
        width: 90vw;

        .job-information-wrapper {
          text-align: left;
          align-items: center;
          justify-content: center;
          width: 90vw;

          .overnight-checkbox {
            flex-direction: row;
            align-self: center;
          }

          div {
            display: flex;
            flex-direction: column;

            input,
            select,
            textarea {
              margin-left: 0px;
            }
          }

          .forecast-container {
            align-items: flex-start;
          }
        }
      }
    }
  }

  .par-page-container {
    .problems-holder {
      .checkbox-item-container {
        .show-hide-wrapper {
          .single-problem-container {
            .materials-table,
            .time-materials-table,
            .subcontractors-table {
              thead {
                display: none;
                flex-direction: column;

                tr {
                  flex-direction: column;
                }
              }
              tbody {
                .time-materials-row {
                  height: 150px;
                }

                tr {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: center;
                  padding: 10px 0;

                  td {
                    .phase-wrapper {
                      flex-direction: column;
                      gap: 10px;
                      width: 100%;

                      &:hover::after {
                        content: none;
                        display: none;
                      }

                      .chosen-code {
                        width: unset;
                        text-overflow: unset;
                        overflow: unset;
                        white-space: unset;
                        text-align: center;
                      }
                    }

                    .subontractor-type {
                      font-size: 24px;
                    }

                    select {
                      width: 50vw;
                    }

                    input {
                      box-sizing: border-box;
                      width: 50vw;
                    }

                    svg {
                      height: 24px;
                    }
                  }
                }

                .material-table-row {
                  .td-total {
                    .total-wrapper {
                      .total-inner-wrapper {
                        justify-content: center;

                        .mat-input {
                          width: unset;
                          min-width: unset;
                          overflow-x: unset;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include new-par-query(969px) {
  .subcontractor-buttons-wrapper {
    button {
      width: auto;
      max-width: 500px;
    }
  }

  .table-container {
    table {
      tbody {
        .table-row {
          .checkbox-container {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

@media (max-width: 1121px) {
  .par-page-container {
    .header-container {
      margin-top: 80px;

      .reset-information-wrapper {
        padding-right: 0;
        margin-bottom: 0;
        flex-direction: column;

        .reset-par {
          margin: 3% 0px;
          width: 68%;
          max-width: 500px;
          height: 38px;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .par-message {
        text-align: center;
        margin: 2px 0px;
      }

      .par-header {
        letter-spacing: 0px;
      }
    }

    .single-problem-container {
      td {
        .cost-wrapper {
          gap: 10px;
          margin: 0 0 0 -15px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .par-page-container {
    .header-container {
      .par-header {
        font-size: 38px;
      }
    }

    .par-information-container {
      .time-materials-container {
        .time-materials-content-container {
          .job-information-wrapper {
            text-align: left;
            padding: 0 15%;

            div {
              display: flex;
              flex-direction: column;

              input {
                margin-left: 0px;
                width: auto;
              }
            }
          }
        }
      }

      .phasecode-table-container {
        .par-phase-container {
          .phase-button-container {
            .phase-code-button {
              width: 100%;
            }
          }
        }
      }
    }

    .problems-holder {
      .checkbox-item-container {
        .single-problem-container {
          .subcontractor-buttons-wrapper {
            flex-direction: column;
          }

          td {
            div {
              display: flex;
              align-items: center;

              .mat-phase-add-btn {
                width: 189px;
                height: 26px;
              }
            }
          }
        }
      }
    }
  }

  .rejected-par-comments {
    textarea {
      padding-right: 15px;
      font-size: 16px;
    }
  }

  .par-page-container.approvals-mode {
    padding-bottom: 180px;
  }
}

@media (max-width: 480px) {
  .rejected-par-comments {
    h2 {
      font-size: 14px;
      padding: 10px;
    }

    textarea {
      min-height: 80px;
    }
  }
}

@media (max-width: 650px) {
  .par-page-container {
    .par-information-container {
      .phasecode-table-container {
        .table-container {
          .wages-container {
            .wages-header {
              justify-content: space-between;
              font-size: 14px;

              .header-employee {
                width: fit-content;
              }

              .header-note {
                margin-right: 0px;
                margin-left: 20px;
              }

              .header-hours {
                margin-right: 20%;
              }
            }

            .wages-table {
              .employee-data {
                font-size: 12px;
                .employee-note {
                  align-self: center;
                  margin-right: 10%;
                  height: 12px;
                  width: 100px;
                }
                .add-work {
                  width: 95px;
                  font-size: 8px;
                }
                .employee-hours {
                  margin-right: 10px;
                  width: 180px;
                }
              }

              .labor-data {
                font-size: 11px;

                .class-hours {
                  margin-right: 25px;
                }
              }
            }
          }
        }
      }

      .main-content-container {
        width: 87vw;

        .job-information-wrapper {
          text-align: left;
          align-items: center;
          justify-content: center;
          width: 90vw;

          .overnight-checkbox {
            flex-direction: row;
            align-self: center;
          }

          div {
            display: flex;
            flex-direction: column;

            input,
            select {
              margin-left: 0px;
            }
          }

          .forecast-container {
            align-items: flex-start;
          }
        }
      }

      .par-checkbox-container {
        margin-bottom: 10%;
      }

      .problems-holder {
        .single-problem-container {
          .table-container {
            table {
              display: flex;
              margin-bottom: 15%;
              justify-content: center;

              tbody {
                width: 100%;

                .table-row {
                  .padding-20 {
                    padding-left: 0;
                    width: 70%;
                  }

                  .select-approved-subs {
                    width: 88%;
                    height: 36px;
                  }

                  .remove-subcontractor {
                    text-align: right;
                    padding-right: 19%;
                    color: white;
                  }
                }
              }

              .table-row,
              .material-table-row {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                height: auto;
                padding: 30px 0;

                .phase-wrapper {
                  .chosen-code {
                    max-width: 200px;
                  }
                }

                td {
                  width: 70%;
                  padding: 0px;
                  margin: 5px 0px;

                  .select-approved-subs,
                  .input-sub-details {
                    margin-left: 0;
                    margin-right: 0;
                  }

                  input {
                    height: 30px;
                  }

                  .input-wrapper {
                    select {
                      height: 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .subcontractor-buttons-wrapper {
        button {
          height: 12vh;
          width: 9rem;
          border-radius: 5px;
          font-size: 20px;
        }
      }

      .time-materials-container {
        margin-bottom: 80px;

        .main-content-container {
          margin: 15% 0;
          width: 100%;
        }
      }
      .phasecode-table-container {
        .par-phase-container {
          div {
            .table-container {
              table {
                th {
                  font-size: 10px;
                }

                .table-row {
                  height: 100px;

                  td {
                    padding: 1px 3px;
                    text-align: center;
                    font-size: 10px;
                    height: 100px;

                    input {
                      width: 50px;
                    }
                  }
                }
              }

              .footer-button {
                width: 90%;
              }
            }
          }
        }
      }
    }
  }
}

@include new-par-query(500px) {
  .subcontractor-buttons-wrapper {
    button {
      width: 100%;
    }
  }

  .main-content-container {
    .job-information-wrapper {
      width: 100%;
    }
  }
}

@media (max-width: 420px) {
  .par-page-container {
    .header-container {
      .par-header {
        padding: 20px 0;
        font-size: 28px;
      }
    }

    .par-information-container {
      .main-content-container {
        .job-information-wrapper {
          padding: 20px 0px;
        }
      }

      .phasecode-table-container {
        .table-container {
          .wages-container {
            .wages-header {
              justify-content: space-between;
              font-size: 12px;

              .header-note {
                margin-left: 11%;
              }

              .header-hours {
                margin-right: 11%;
              }
            }

            .wages-table {
              .employee-data {
                height: fit-content;
                font-size: 11px;
                padding: 5px 0px;

                .employee-name {
                  width: 40px;
                }

                .employee-note {
                  margin-right: 5%;
                  height: 11px;
                  font-size: 11px;
                  width: 65px;
                  margin-left: 2%;
                }
                .employee-hours {
                  margin-right: 9px;
                }
              }

              .labor-data {
                .class-hours {
                  width: 60px;
                  font-size: 11px;
                }
              }
            }
          }
          .notes-section-wrapper {
            .container {
              flex-direction: column;
            }
          }
        }
      }
    }

    .save-button-wrapper {
      flex-direction: column;
    }
  }

  .problems-holder {
    .checkbox-item-container {
      .show-hide-wrapper {
        .single-problem-container {
          .issues-table,
          .hrvc-table {
            thead {
              tr {
                justify-content: space-between;

                .edit,
                .delete {
                  display: none;
                }
              }
            }

            tr {
              display: flex;
              flex-wrap: wrap;

              td {
                flex: 1;

                &.issue-edit,
                &.issue-delete,
                &.edit,
                &.delete {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-basis: 45%;
                }
              }
            }
          }
          .material-total-wrapper {
            font-size: 12px;
          }
        }

        .material-table-row {
          width: 100%;
          border: 1px solid black;
        }
      }
    }
  }
}

.login-page-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .svg-wrapper {
    img {
      pointer-events: none;
    }
  }

  .login-svg {
    position: absolute;
    width: 100%;
    z-index: 0;
  }

  .logo {
    width: 20%;
    position: absolute;
    top: 3%;
    left: 3%;
    z-index: 1;
    pointer-events: none;
  }

  .login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8%;
    z-index: 1;

    .error-message {
      position: absolute;
      top: 21%;
    }

    form {
      text-align: right;
      width: 35%;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      .login-button {
        color: $background-white;
        background-color: black;
        font-size: 16px;
        padding-left: 20px;
        margin-top: 15px;
        border-radius: 0px;
        height: 45px;
        border: 1px solid black;
        cursor: pointer;

        .loading {
          visibility: hidden;
        }

        .show-loading-login {
          position: absolute;
          visibility: visible;
          bottom: calc(50% - 101px + 4vw);
          left: calc(50% - 7.5px);
          width: 15px;
          height: 15px;
          border: 3px solid rgba(255, 255, 255, 0.3);
          border-radius: 50%;
          border-top-color: grey;
          animation: spin 1s ease-in-out infinite;
          -webkit-animation: spin 1s ease-in-out infinite;
        }

        @keyframes spin {
          to {
            -webkit-transform: rotate(360deg);
          }
        }
        @-webkit-keyframes spin {
          to {
            -webkit-transform: rotate(360deg);
          }
        }
      }
    }
    .google-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35%;
      color: black;
      background-color: $background-white;
      font-size: 16px;
      padding-left: 20px;
      margin-top: 15px;
      border-radius: 0px;
      height: 45px;
      border: 3px solid black;
      font-weight: 700;
      cursor: pointer;

      .google-g {
        padding-right: 25px;
      }
    }
    .input-wrapper {
      display: flex;
      flex-direction: row;
      input {
        padding-left: 20px;
        margin-top: 15px;
        border-radius: 0px;
        height: 45px;
        border: 1px solid black;
        width: calc(100% - 24px);
      }
      .open-closed-eye {
        width: 0px;
        transform: translate(-38px, 28px);
        img {
          cursor: pointer;
        }
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      box-shadow: 0 0 0 30px $background-white inset;
    }
  }

  .svg-bottom {
    position: absolute;
    bottom: 0%;
  }
}

@media screen and (max-width: 768px) {
  .login-page-container {
    .logo {
      top: 1.5%;
    }
  }
}

.single-problem-container {
  width: 100%;
  height: 10%;

  textarea {
    width: 100%;
    padding-top: 10px;
    border: none;
    text-align: center;
    background-color: $dark-grey;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.confirmation-modal-container {
  @include flex-column-center;
  justify-content: space-between;
  height: 100%;
  padding: 20px 10px;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #d0d0d0;
    border-radius: 8px;

    &:active {
      background-color: #bbbbbb;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 8px;

    &:hover {
      cursor: pointer;
    }
  }

  .message {
    padding: 10px 7.5px 0px 7.5px;
    font-size: 18px;
    overflow-y: auto;
    height: 60%;
    white-space: pre-line;
  }

  .button-wrapper {
    display: flex;
    justify-content: space-around;
    letter-spacing: 0.14em;
    width: 100%;

    button {
      background-color: #d0d0d0;
      border: 2px solid black;
      border-radius: 25px;
      width: 125px;
      height: 35px;
      font-size: 17px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: transparentize($color: #d0d0d0, $amount: 0.3);
      }
    }

    .button-alternate {
      background-color: black;
      color: white;

      &:hover {
        background-color: transparentize($color: black, $amount: 0.3);
      }
    }

    .smaller-text {
      font-size: 13px;
    }
  }
}

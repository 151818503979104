.approver-changes-wrapper {
  table.requested-changes {
    border: 1px solid black;
    border-collapse: collapse;
    width: 86.75%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;

    th {
      border: 1px solid black;
      padding: 1% 0;
      background-color: black;
      color: #fff;
      font-size: x-large;
      font-weight: 300;
    }

    td {
      border: 1px solid black;
      background-color: #fff;
    }
  }

  table.red {
    th {
      background-color: #721313;
    }
  }
}

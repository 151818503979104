@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin base-btn {
  button {
    width: 100px;
    height: 30px;
    margin: 20px 10px;
    border-radius: 15px;
    border: none;
    color: $background-white;
    cursor: pointer;
  }

  .minimize-button {
    background-color: black;
  }

  .clear-button {
    background-color: #767676;
  }
}

@mixin green-gradient {
  border-color: "#1A5F02";
  background: linear-gradient(
    90deg,
    rgba(2, 185, 42, 1) 0%,
    rgba(102, 214, 127, 1) 0%,
    rgba(185, 247, 198, 1) 0%,
    rgba(255, 255, 255, 1) 10%
  );

  svg {
    color: #02b92a;
  }
}

@mixin blue-gradient {
  border-color: #0228af;
  background: rgb(2, 12, 255);
  background: linear-gradient(
    90deg,
    rgba(2, 12, 255, 1) 0%,
    rgba(79, 86, 255, 1) 0%,
    rgba(183, 186, 255, 1) 0%,
    rgba(255, 255, 255, 1) 10%
  );

  svg {
    color: #020cff;
  }
}

@mixin orange-gradient {
  border-color: #fca261;
  background: rgb(255, 217, 173);
  background: linear-gradient(
    90deg,
    rgba(255, 217, 173, 1) 0%,
    rgba(255, 255, 255, 1) 10%
  );

  svg {
    color: #ff8a00;
  }
}

@mixin red-gradient {
  border-color: #ff0505;
  background: rgb(255, 185, 185);
  background: linear-gradient(
    90deg,
    rgba(255, 185, 185, 1) 0%,
    rgba(255, 255, 255, 1) 10%
  );
  svg {
    color: #ff0505;
  }
}

@mixin purple-gradient {
  border-color: #47047b;
  background: rgb(255, 185, 185);
  background: linear-gradient(
    90deg,
    rgba(236, 211, 255, 1) 0%,
    rgba(255, 255, 255, 1) 12%
  );
  svg {
    color: #47047b;
  }
}

@mixin new-par-query($size) {
  @media (max-width: $size) {
    .par-page-container {
      .par-information-container {
        @content;
      }
    }
  }
}

@mixin checkbox-table {
  .checkbox-item-container {
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 84vw;

    .single-problem-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 20px;

      table {
        text-align: left;
        width: 83.5vw;
        font-size: 14px;
        border-collapse: collapse;

        .tbody {
          tr {
            border: 1px solid black;
          }
        }

        ::-webkit-scrollbar {
          height: 3px;
          width: 8px;
        }

        ::-webkit-scrollbar-track {
          background-color: white;
        }

        ::-webkit-scrollbar-thumb {
          background-color: gray;
          height: 3px;
          border-radius: 5px;
        }
      }

      thead {
        width: 100%;
        background-color: transparent;

        tr {
          width: 100%;
          border: 1px solid transparent;
          border-bottom: 1px solid black;

          .header {
            text-align: left;
            color: black;
            padding: 10px;
            font-weight: bold;
            font-size: medium;
            border: none;
            background-color: transparent;
          }
        }
      }
    }

    .add-button {
      height: 40px;
      width: 45vw;
      min-width: 250px;
      background-color: #767676;
      color: white;
      border: none;
      font-size: 24px;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}

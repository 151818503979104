.par-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: darken($color: white, $amount: 20%);

  &.approvals-mode {
    background-color: darken($color: white, $amount: 20%);
    padding-bottom: 200px;

    .header-container {
      .par-header {
        color: #721313;
      }
    }
  }

  .header-container {
    text-align: center;
    margin: auto;
    width: 100%;
    margin-bottom: 2%;
    margin-top: 60px;

    .par-message {
      text-align: end;
      margin: 0 20px 3px 0;
    }

    .par-header {
      letter-spacing: 5px;
      word-spacing: 10px;
      font-size: 3rem;
      font-weight: 400;
      transition: color 0.3s ease;

      &.approvals-header {
        color: #721313;
      }
    }

    .reset-information-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
      padding-right: 1.2%;
      margin-bottom: 0.4%;

      .reset-par {
        @include flex-center;
        background-color: black;
        color: white;
        font-size: 20px;
        width: 115px;
        height: 48px;
        transition: opacity 0.2s ease-in;
        cursor: pointer;
        border: 2px solid black;

        &:hover {
          opacity: 0.8;
        }

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }

  .par-information-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .job-information {
      width: 86.8%;
      height: 56px;
      border-radius: none;
      border: none;
      color: white;
      font-size: 28px;
      background-color: black;
      background-image: url("../img/lg-yellow-design.svg");
      background-repeat: no-repeat;
      background-position: bottom right;
      cursor: pointer;
    }

    .header-alternate {
      background-image: url("../img/lg-yellow-design.svg");
      background-color: #721313;
      cursor: pointer;
    }

    .par-phase-total {
      margin: 30px 0 0 0;
    }

    .main-content-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-top: 20px;
      gap: 5%;

      .job-information-wrapper {
        text-align: right;
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 100%;

        .date-container {
          margin-top: 0px;

          input {
            width: 300px;

            &::-webkit-datetime-edit-month-field {
              color: black;
            }

            &::-webkit-datetime-edit-year-field {
              color: black;
            }

            &::-webkit-datetime-edit-text {
              color: black;
            }

            color-scheme: light;
          }
        }

        .par-button-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          button {
            width: 302px;
            background-color: #767676;
            color: white;
            margin: 5px 0;
            padding: 4px 0;
            border: none;
            font-size: 16px;
            cursor: pointer;
          }
        }

        .overnight-checkbox {
          display: flex;
          margin-left: 10px;
          width: 295px;
          align-items: center;
          align-self: flex-end;

          input {
            width: 15%;
          }
        }

        div {
          select {
            width: 302px;
            height: 32px;
            margin-left: 10px;
            border-radius: 0;
          }

          input {
            width: 295px;
            height: 26px;
            margin-left: 10px;
            border-radius: 0;
            border: 1px solid black;
          }
        }
        input {
          text-indent: 5px;
          font-family: "Roboto", sans-serif;
        }

        input[type="checkbox"] {
          text-indent: 0px;
          text-align: center;
          margin-left: -10px;
        }

        .safety-topic-container {
          padding-bottom: 3%;
        }
      }
    }

    .spinner {
      @include flex-center;
    }

    .time-materials-container {
      width: 100%;
      margin: 0 -7.5% 15px 0;
    }

    .hide-header {
      display: none;
    }
    .section-label {
      margin: 30px 0;
      display: inline-block;
      background: #383838;
      color: white;
      width: calc(83% + 30px);
      height: 50px;
      font-size: 28px;
      border: none;
      cursor: pointer;
    }

    .notes-section-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      .notes-wrapper {
        width: 85%;
        display: flex;
        flex-direction: column;
      }

      .foreman-daily-journal {
        margin-bottom: 20px;
      }

      .foreman-label {
        margin-top: 20px;
      }

      label {
        margin: 0 0 8px 0;
        text-align: left;
        font-weight: 600;
      }

      textarea {
        width: 99%;
        font-family: "roboto";
      }

      .subcontractor-disclaimer {
        margin: 20px 0px;
        width: 88%;
      }
    }
    .hide-notes-wrapper {
      display: none;
    }

    .par-checkbox-container {
      accent-color: black;
      margin: 1% auto;
      padding-top: 3%;
      display: flex;

      justify-content: center;
      max-width: 85vw;

      .checkbox-item {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10px;
        gap: 30px;

        .checkbox-wrapper {
          background-color: #767676;
          padding: 5px 5px 5px 5px;
          border-radius: 5px;
          max-width: max(8vw, 180px);
          min-width: max(8vw, 180px);
          display: grid;
          grid-template-columns: 9fr 1fr;
          justify-items: center;
          align-items: center;

          .checkbox-label {
            color: white;
            font-size: 18px;
            font-weight: 400;
            padding: 0;
            margin: 0;
          }

          input[type="checkbox"] {
            width: 30px;
            height: 30px;
            margin-right: 8px;
            cursor: pointer;
            font-size: 17px;
            visibility: hidden;

            &:disabled {
              cursor: default;
            }
          }
        }
      }
    }

    .heading-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #383838;
      color: white;
      width: 100%;
      height: 50px;
      font-size: 28px;
      margin-bottom: 15px;
      cursor: pointer;
      .tm {
        margin-bottom: 0px;
      }
      .section-label {
        display: inline-block;
        background: #383838;
        color: white;
        width: calc(85% + 30px);
        height: 50px;
        font-size: 28px;
        border: none;
        cursor: pointer;
      }
    }

    .material-container {
      margin-top: 2% 0;
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 20px;
      align-items: center;

      label {
        margin-bottom: 5px;
      }

      button {
        border: 1px solid black;
        border-radius: 0;
        background-color: #767676;
        color: white;
        font-size: 24px;
        border: none;
        height: 40px;
        width: 1020px;
        cursor: pointer;
      }
    }

    .subcontractor-buttons-wrapper {
      margin-top: 2%;
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 20px;
      align-items: center;

      label {
        margin-bottom: 5px;
      }

      button {
        border: 1px solid black;
        border-radius: 0;
        background-color: #767676;
        color: white;
        font-size: 24px;
        border: none;
        height: 40px;
        width: 500px;
        cursor: pointer;

        &:disabled {
          cursor: default;
        }
      }
    }

    .minimize-button-wrapper {
      @include base-btn;
    }

    .is-selected {
      background: $light-grey;
    }
  }

  input[type="checkbox"] {
    transform: scale(1.15);
    width: 0px;
    height: 20px;
    margin-right: 8px;
    cursor: pointer;
    font-size: 17px;
  }

  input[type="checkbox"]:after,
  input[type="checkbox"]::after {
    content: " ";
    background-color: black;
    display: inline-block;
    padding-bottom: 2px;
    color: #00bff0;
    width: 18px;
    height: 18px;
    visibility: visible;
    border: 4px black solid;
    padding-left: 2px;
    border-radius: 5px;
  }

  input[type="checkbox"]:checked:after,
  input[type="checkbox"]:checked::after {
    content: url(../img/check-solid-cropped.svg);
    padding: 0 2px 2px 0;
    font-weight: bold;
    font-size: 20px;
    background-color: black;
    color: white;
  }

  .checkbox-container {
    .checkmark-small {
      transform: scale(0.5);
    }
  }

  input[type="checkbox"]:disabled:after,
  input[type="checkbox"]:disabled::after {
    content: url(../img/check-solid-cropped.svg);
    padding: -5px;
    font-weight: bold;
    font-size: 20px;
    background-color: black;
    color: white;
  }

  button:disabled {
    opacity: 0.4;
  }

  .save-button-wrapper {
    margin-top: 2%;
    display: flex;
    align-items: flex-end;

    button {
      cursor: pointer;
    }

    .add-phase {
      width: 150px;
      height: 40px;
      color: $background-white;
      background-color: black;
      margin: 10px;
      border-radius: 20px;
      border: none;
      cursor: pointer;
      text-decoration: none;
      font-size: 13px;
    }

    .submit-button {
      margin-top: 0px;
      background-color: #383838;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
}

.notes-section-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .notes-wrapper {
    width: 85%;
    display: flex;
    flex-direction: column;
  }

  .foreman-daily-journal {
    margin-bottom: 20px;
  }

  .foreman-label {
    margin-top: 20px;
  }

  label {
    margin: 0 0 8px 15px;
    text-align: left;
    font-weight: 600;
  }

  textarea {
    width: 100%;
    font-family: "roboto";
  }

  .subcontractor-disclaimer {
    margin: 20px 0px;
    width: 71.4%;
  }

  .material-total-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 1em;
    font-weight: bold;
    padding-top: 0%;
    margin-right: 4.75%;
  }

  .material-container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;

    label {
      margin-bottom: 5px;
    }

    button {
      border: 1px solid black;
      border-radius: 0;
      background-color: #767676;
      color: $background-white;
      font-size: 24px;
      border: none;
      height: 40px;
      width: 1020px;
      cursor: pointer;
    }
  }

  .subcontractor-buttons-wrapper {
    margin-top: 2%;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;

    label {
      margin-bottom: 5px;
    }

    button {
      border: 1px solid black;
      border-radius: 0;
      background-color: #767676;
      color: $background-white;
      font-size: 24px;
      border: none;
      height: 40px;
      width: 500px;
      cursor: pointer;
    }
  }

  .minimize-button-wrapper {
    @include base-btn;
  }
}
.hide-notes-wrapper {
  display: none;
}

.is-selected {
  background: $light-grey;
}

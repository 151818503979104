.verification-totals-wrapper {
  text-align: center;
  display: grid;
  font-size: 18px;
  grid-template-columns: 100px auto;

  h1 {
    text-align: center;
    font-size: 26px;
    text-decoration: underline;
  }

  .tables {
    width: 100%;
    display: grid;
    grid-column: 2;

    .header {
      font-size: 20px;
      font-weight: 500;
    }

    .table {
      display: grid;
      grid-template-columns: 100%;
      text-align: center;
      width: 100%;

      h1 {
        font-size: 26px;
        text-decoration: underline;
      }

      table {
        background-color: $background-white;
        width: 100%;

        td {
          border: 1px solid gray;
          text-align: center;
        }

        th {
          border: 1px solid gray;
        }
      }
    }
  }

  .par-phase-total,
  .par-phase-total-equipment {
    text-align: center;
    font-size: 22px;
  }

  .table-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: 1em;
    overflow-x: auto;
    max-width: 90vw;

    table {
      width: 90%;
      background-color: white;
      font-size: 18px;
      text-align: center;
    }

    table,
    th,
    td {
      border: 1px solid black;
      border-collapse: collapse;
    }

    td {
      padding: 2px 10px;
    }
  }

  table.requested-changes {
    width: 90%;
    margin: 20px 0px 20px 1.5%;
    justify-self: center;
  }
}

.par-page-container {
  .verification-totals-wrapper {
    width: 86.8%;
    padding: 10px 12px;
    margin: 30px 0;
    display: block;

    .tables {
      width: 100%;
      display: flex;
      flex-direction: column;
      grid-column: unset;

      h1 {
        margin: 20px 0;
        text-decoration: none;
        color: #333;
        font-weight: 500;
      }

      .outer-wrapper {
        width: 100%;

        .table-wrapper {
          margin-left: 0;
          max-width: 100%;

          table {
            width: 100%;
            border: none;
            border-collapse: collapse;

            thead {
              background-color: $background-white;

              tr {
                th {
                  font-weight: 500;
                  padding: 12px;
                  border: none;
                  border-bottom: 2px solid #dee2e6;
                }
              }
            }

            .desktop-tbody,
            .mobile-tbody {
              tr {
                td {
                  padding: 12px;
                  border: none;
                  border-bottom: 1px solid #dee2e6;
                }

                &:hover {
                  background-color: #dfe1e2;
                }
              }

              tr:nth-child(even) {
                background-color: white;
              }

              tr:nth-child(odd) {
                background-color: #e6eef1;
              }
            }
          }
        }
      }

      .par-phase-total,
      .par-phase-total-equipment {
        margin: 15px 0;
        color: #495057;
      }
    }
  }
}

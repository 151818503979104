@media (max-width: 650px) {
  .report-notes-wrapper {
    display: block;

    .break-wrapper {
      display: unset;
    }

    .section-content {
      margin-left: 5%;
      display: flex;
      flex-wrap: wrap;

      .details {
        width: 90%;
      }
    }
  }
}

@media print {
  .report-notes-wrapper {
    grid-template-columns: 30px auto;

    .section-content {
      margin: 0;
      width: 100%;
      height: 100%;
      padding-left: 0;

      .label {
        font-size: 19px;
        margin-right: 10px;
        inline-size: 200px;
        margin-left: 20px;
      }

      .details {
        width: 55%;
        font-size: 14px;
        margin-left: 50px;
      }
    }
  }
}

.action-buttons-wrapper {
  display: flex;
  gap: 10px;
  padding: 15px;
  background: transparent;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 2;

  button,
  a {
    padding: 8px 24px;
    border-radius: 20px;
    border: none;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:hover:not(:disabled) {
      transform: translateY(-1px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      transform: none;
      box-shadow: none;
    }

    .button-icon {
      margin-right: 8px;
      font-size: 14px;
    }
  }

  .save {
    min-width: 150px;
    max-height: 32px;
    background: #000000;
    color: white;

    &:hover:not(:disabled) {
      background: #333333;
    }
  }

  .reject {
    min-width: 150px;
    max-height: 32px;
    background: #8b0000;
    color: white;

    &:hover:not(:disabled) {
      background: #8b0000;
    }
  }

  .add-phase {
    min-width: 150px;
    max-height: 32px;
    background: #000000;
    color: white;

    &:hover:not(:disabled) {
      background: #333333;
    }
  }

  .cancel {
    min-width: 100.5px;
    background: #f3f4f6;
    color: #4b5563;
    border: 1px solid #e5e7eb;
    width: 150px;
    height: 32.8px;

    &:hover:not(:disabled) {
      background: #e5e7eb;
    }
  }

  .print {
    min-width: 100.5px;
    background: white;
    color: #4b5563;
    border: 1px solid #e5e7eb;

    &:hover:not(:disabled) {
      background: #f3f4f6;
    }
  }

  .email {
    min-width: 150px;
    background: #000000;
    color: white;

    &:hover:not(:disabled) {
      background: #333333;
    }
  }

  &.approvals {
    margin-top: -1px;
  }

  .select-print & {
    padding-left: 115px;

    .print {
      background: #000000;
      color: white;
      border: none;

      &:hover:not(:disabled) {
        background: #333333;
      }
    }
  }

  @media print {
    display: none;
  }
}

.rejected-par-comments {
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 5;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 0 0;
  margin-top: 500px;

  h2 {
    margin: 0;
    padding: 12px 15px;
    font-size: 16px;
    background: #8b0000;
    color: white;
    text-align: center;
    letter-spacing: 0.05em;
    border-radius: 8px 8px 0 0;
  }

  textarea {
    width: 100%;
    padding: 15px;
    padding-right: 5rem;
    min-height: 60px;
    max-height: 120px;
    font-family: inherit;
    box-sizing: border-box;
    resize: vertical;
    border: none;
    background: white;
    color: #333;
    font-size: 14px;

    &::placeholder {
      color: #666;
    }

    &:focus {
      outline: none;
    }
  }
}

@media (max-width: 1000px) {
  .par-page-container {
    .action-buttons-wrapper {
      width: 100%;
      margin: 0;
      box-sizing: border-box;
      flex-direction: column-reverse;
    }
  }
}

@media (max-width: 1180px) {
  .verification-page-container {
    .report-phase-tables-wrapper {
      .verification-phase-code-wrapper {
        .empty-data-container {
          margin-left: 9%;
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .verification-page-container {
    .report-phase-tables-wrapper {
      .verification-phase-code-wrapper {
        .empty-data-container {
          margin-left: 9%;
        }

        .no-phase-data {
          width: 135px;
        }

        .no-phase-data.short {
          width: 95px;
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .report-phase-tables-wrapper {
    max-width: 97vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-template-columns: 0px auto;
    }

    .verification-phase-code-wrapper {
      max-width: 90vw;
      min-width: 90vw;
      display: flex;
      flex-direction: column;
      align-items: center;

      .empty-data-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: 0px;
        padding: 0px;
        gap: 10px;

        .no-phase-data {
          width: 135px;
          white-space: nowrap;
        }

        .no-phase-data.short {
          width: 100px;
        }
      }

      .section-content {
        width: 100%;
        display: unset;

        .verification-checkbox-wrapper {
          width: 70px;
        }

        .table {
          width: 90vw;
          margin: 0px;
          padding: 0px;
        }

        .table-wrapper {
          margin-left: 0.35em;
          margin-bottom: 10px;
          justify-content: center;

          .wages-container {
            .wages-header {
              grid-template-columns: 25vw 1fr 100px;
              font-size: 12px;
            }

            .wages-table {
              .employee-data {
                grid-template-columns: 25vw 1fr 100px;
                font-size: 12px;
              }
            }
          }

          .wages-container {
            .wages-table {
              .labor-data {
                grid-template-columns: 50px 1fr 38px;
                font-size: 11px;
              }
            }
          }

          table {
            table-layout: fixed;
            border-collapse: separate;
            border-spacing: 0;
            width: fit-content;
            max-width: fit-content;
            min-width: unset;

            tbody {
              tr {
                .vertical-header {
                  position: sticky;
                  top: 0;
                  left: 0;
                  background-color: white;
                  z-index: 0;
                  width: 35px;
                  vertical-align: bottom;
                  text-align: center;
                  padding: 10px 5px;
                  border-bottom: 1px solid black;
                  border-right: 1px solid black;

                  span {
                    -ms-writing-mode: tb-rl;
                    -webkit-writing-mode: vertical-rl;
                    writing-mode: vertical-rl;
                    transform: rotate(180deg);
                    white-space: nowrap;
                  }
                }

                td {
                  width: 200px;
                  border-bottom: 1px solid black;
                  border-right: 1px solid black;
                  padding: 2px 10px;
                }

                .first-column {
                  border-left: 1px solid black;
                }
              }
            }
          }
        }

        .phase-table-wrapper {
          display: block;
          box-shadow: 0px 0px 0px 2px black inset;
        }
      }
    }
  }
}

@media print {
  .report-phase-tables-wrapper {
    .verification-phase-code-wrapper {
      .section-content {
        grid-template-columns: 0px auto;

        .table {
          @include flex-center;
          width: 100%;
          grid-column: 2;
          padding: 0px;
          margin: 0px;

          h1 {
            width: 80%;
          }
        }

        .table-wrapper {
          max-width: none;
          box-shadow: none;

          .wages-container {
            .wages-header {
              white-space: nowrap;
              grid-template-columns: 250px 1fr 125px;
            }

            .wages-table {
              page-break-inside: auto;
              .employee-data {
                height: 2.25rem;
                page-break-inside: avoid;
                grid-template-columns: 250px 1fr 125px;

                .employee {
                  overflow-x: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  margin-top: 0px;

                  .employee-id {
                    overflow-x: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                }

                .employee-note {
                  overflow-x: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  margin-top: 0px;
                }
              }

              .labor-data {
                grid-template-columns: 50px 1fr 45px;
                page-break-inside: avoid;
                height: 2.1rem;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 847px) {
  .verification-page-container {
    .daily-ticket-container {
      right: 3.5%;
    }
  }
}

@media (max-width: 650px) {
  .verification-page-container {
    & > * {
      grid-template-columns: auto;

      .section-content-table {
        margin: 0px;
      }

      table {
        width: 75%;
        align-self: center;
      }
    }

    .verification-checkbox-wrapper {
      width: 100px;
      height: 40px;
    }

    .incidents-and-injuries-wrapper {
      display: block;

      .report-notes-wrapper {
        .section-content {
          display: flex;
          flex-direction: column;
          margin-left: 6%;
          margin-bottom: 6%;

          .label {
            font-size: 19px;
            font-weight: 500;
            width: 300px;
            margin: 0px;
            padding: 0px;
            white-space: nowrap;
          }

          .details {
            font-weight: 400;
            width: 75%;
            margin: 0px;
            padding: 0px;
            white-space: pre-line;
          }
        }
      }
    }

    .verification-hr-wrapper {
      display: flex;
      justify-content: center;
      width: 97vw;

      .verification-hr {
        width: 90%;
        height: 2px;
        border: none;
        margin: 10px 0px;
      }
    }

    .reject-message-text {
      margin-left: 0;

      label {
        text-indent: 0;
      }
    }

    .confidential-msg {
      grid-template-columns: 0px auto;
      background-color: white;
      margin: unset;
      margin-left: -90px;
      height: 40px;
      padding-top: 20px;
      text-align: center;
    }

    &.select-print {
      .action-buttons-wrapper {
        margin-left: -40px;
      }
    }
  }

  .approval-verification {
    & > * {
      background-color: #d4d5d5;
    }
  }

  .select-print {
    & > * {
      background-color: white;
    }
  }
}

@media print {
  @page {
    margin: 36px 0px 36px 0px;
    size: A4 portrait;
    background: $background-white;
  }

  .navbar-wrapper {
    display: none;
  }

  .container {
    .body-wrapper {
      margin-top: 0px;

      .verification-page-container {
        width: 100vw;
        padding-top: 0;
        margin-top: 0;
        background: $background-white;

        .verification-hr-wrapper {
          grid-template-columns: 0px auto;

          .verification-hr {
            grid-column: 2;
            border: none;
            border-top: 1px solid black;
            width: 90%;
          }
        }

        .verification-checkbox-wrapper {
          display: none;
        }

        .hide-print {
          display: none;
        }

        .hide-print {
          display: none;

          table {
            display: none;
          }
        }

        .report-phase-tables-wrapper {
          .verification-phase-code-wrapper {
            .empty-data-container {
              margin: 20px 0px 20px 0px;

              .short {
                width: 100px;
              }
            }
          }
        }

        .confidential-msg {
          margin: 0;
        }

        .verification-approvals-wrapper {
          grid-template-columns: 0px auto;
        }
      }

      .blur {
        filter: unset;
        -webkit-filter: unset;
        -moz-filter: unset;
        -o-filter: unset;
        -ms-filter: unset;
      }

      .confidential-msg {
        width: 100%;
        margin: 0px;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 3%;
      }
    }
  }

  .action-buttons-wrapper {
    display: none !important;
  }
}

@media (max-width: 500px) {
  .action-buttons-wrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}

@media (max-width: 1045px) {
  .par-page-container {
    .par-information-container {
      .main-content-container {
        .job-information-wrapper {
          .weather-information-wrapper {
            .forecast-wrapper {
              align-items: flex-start;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .par-page-container {
    .par-information-container {
      .main-content-container {
        .job-information-wrapper {
          .weather-information-wrapper {
            .forecast-wrapper {
              align-items: flex-start;
            }

            .get-weather-btn {
              margin-top: 2.4%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .par-page-container {
    .par-information-container {
      .main-content-container {
        .job-information-wrapper {
          .weather-information-wrapper {
            margin: 10px 0;

            .forecast-wrapper {
              align-items: flex-start;
            }
          }
        }
      }
    }
  }
}

.add-issue-modal-container {
  height: 100%;
  width: 100%;
  position: relative;

  .header-img {
    height: 13%;
    position: absolute;

    img {
      position: absolute;
      left: -82px;
      bottom: -3px;
      transform: scaleX(-1) scale(78%);
      image-rendering: auto;
      pointer-events: none;
    }
  }

  .header-wrapper {
    display: grid;
    grid-template-columns: 92% 8%;
    height: 13%;
    width: 100%;
    background-color: black;
    color: white;
    text-align: center;
    align-items: center;
    margin-left: -1px;
    border-radius: 15px 15px 0 0;
    border-right: 2px solid black;

    .header-graphic {
      position: absolute;
    }

    .add-header {
      width: 90%;
      padding-left: 10%;
    }

    .close-modal-button {
      width: 90%;
      height: 24px;
      background-color: transparent;
      border: none;
      font-size: 14px;
      color: gray;
      cursor: pointer;
      padding: 0px;
      margin: 0px;
    }
  }

  .body-wrapper {
    margin: 0px;
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      font-size: 14px;
      font-weight: 700;
    }

    .type-date-wrapper {
      width: 80%;
      height: 15%;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;

      .label-input-wrapper {
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;

        .label-hidden {
          display: none;
        }

        .select-container {
          position: relative;
          display: inline;
          border: 1px solid rgba(0, 0, 0, 0.75);
          background-color: white;

          select {
            height: 100%;
            width: 100%;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            padding: 0.5em 0.76em 0.5em 0.55em;
            background: transparent;
            border-radius: 3px;
            font-size: 1em;
            border: 0px;
            font-size: 14px;
            text-overflow: ellipsis;

            &::-ms-expand {
              display: none;
            }
          }

          .type-input {
            color: black;
            font-family: "Roboto", sans-serif;
          }

          .type-input-invalid {
            color: lightgray;
            text-align: left;
            height: 44px;
          }

          &:after {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            pointer-events: none;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            top: 0.8em;
            right: 0.3em;
            border-top: 5px solid black;
            opacity: 1;
          }
        }

        .select-container {
          height: 55.5%;

          .type-input-invalid {
            height: 32px;
          }
        }

        .date-input-wrapper {
          width: 100%;
          height: 59.5%;

          .date-input {
            box-sizing: border-box;
            height: 100%;
            width: 100%;
            padding-left: 6%;
            font-family: "Roboto", sans-serif;
            color: black;
            border: 1px solid rgba(0, 0, 0, 0.75);

            &::-webkit-calendar-picker-indicator {
              background-image: url("../../img/calendar-icon.svg");
              margin-right: 2%;
              cursor: pointer;
              transform: scale(1.25);
            }
          }

          .invalid {
            &::-webkit-datetime-edit {
              color: lightgray;
            }
          }
        }
      }
    }

    .description-label-input-wrapper {
      width: 100%;
      height: 50%;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .description-label {
        display: flex;
        width: 90%;
        padding: 2px;
        padding-left: 4px;
        margin-left: -2px;
      }

      .description-input-wrapper {
        width: 90%;
        height: 90%;

        .label-hidden {
          display: none;
        }

        .description-input {
          width: 100%;
          height: 100%;
          font-family: "Roboto", sans-serif;
          padding: 5px;
        }
      }
    }

    .save-modal-button {
      font-size: 18px;
      font-weight: 500;
      margin: 0px;
      margin-top: 41px;
      padding: 4px 10px;
      border-radius: 20px;
      border: 1px solid rgba(0, 0, 0, 0.75);
      width: 125px;
      height: 40px;
      color: $background-white;
      background-color: black;
      cursor: pointer;
    }
  }

  .letter-spacing {
    letter-spacing: 0.14em;
  }
}
